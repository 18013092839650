// dependancies
import { httpCallables } from "../../../firebase";

// Redux
import store from "../../store";
import { addSnackbar } from "../../snackbarSlice";
import { publishTask, setTask } from "../../tasksSlice";

// Rx
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import { tap, map, catchError, exhaustMap } from "rxjs/operators";
import { captureException } from "../../../utils/errorHandlers";
import { hideLoader, showLoader } from "../utils";
import { navigationService } from "../../../utils/navigation";

const CALLBACK = httpCallables.taskFunctions;
const ACTION = "publishTask";
const ERROR_MSG = `PUBLISH_TASK`;

export const publishTaskEpic = (action$, state$, { fetchAsObservable }) => {
  return action$.pipe(
    // queues an offline task for submitting a task
    ofType(publishTask.type),
    tap(() => showLoader()),
    map(({ payload }) => payload),
    exhaustMap((args) =>
      fetchAsObservable(CALLBACK, ACTION, ERROR_MSG, args).pipe(
        // open a snackbar to alert the user that the task was created
        tap(({ task, offline_task_id }) => alertUser(task.id, offline_task_id)),

        tap(({ task }) => {
          navigationService.navigate(`/tasks?course_id=${task.course_id}`);
        }),
        tap(() => hideLoader()),
        // Replace the placeholder task with the newly created task
        map(({ task, submissions }) => ({
          type: setTask.type,
          payload: { task, submissions }
        })),

        catchError((error) => {
          hideLoader();
          // TODO: handle error
          captureException(error, "Error in publishTaskEpic");
          return EMPTY;
        })
      )
    )
  );
};

function alertUser(task_id, offline_task_id) {
  store.dispatch({
    type: addSnackbar.type,
    payload: {
      message: {
        id: "task.saved",
        defaultMessage: "Assignment was created successfully"
      },
      actions: [
        {
          intlId: "undo",
          intlDefaultMsg: "undo",
          callBack: "undoPublishTask" // TODO: don't pass a callback, dispatch an action
        }
      ],
      data: {
        offline_task_id,
        task_id,
        taskId: task_id
      }
    }
  });
}
