import { firestore, auth } from "../firebase";
import { setSelectedQuestion } from "../redux/grSlice";
import { updateTaskSubmissionStatus } from "../redux/tasksSlice";
import { httpCallables } from "../firebase";
import store from "../redux/store";
import { addSnackbar } from "../redux/snackbarSlice";
import { captureException } from "../utils/errorHandlers";
import userAPI from "./user";
import { updateGrStateUiOnly, setStage } from "../redux/grSlice";
import { add } from "date-fns";
import { setSelectedInteractionId } from "../redux/interactionsSlice";
import { navigationService } from "../utils/navigation";
import { doc, setDoc, updateDoc } from "firebase/firestore";

const grAPI = {
  updateGrState: (payload) => {
    const state = store.getState();
    const userId = state.firebase.auth.uid;
    const textId = state.texts.selectedTextId;
    const isImpersonation = state.user?.original_auth !== -1;

    if (isImpersonation) store.dispatch(updateGrStateUiOnly(payload));
    else {
      const docRef = doc(firestore, `sq3r/${userId}/texts/textId`);
      setDoc(docRef, payload, { merge: true })
        .then(() => {
          const now = new Date();
          userAPI.log({
            action_name: "GR_STATE_UPDATE",
            user_id: userId,
            text_id: textId,
            created_at: now,
            ttl: add(now, { months: 1 }),
            payload: payload
          });
        })
        .catch((err) => {
          captureException(err, `Failed to save GR status to firebase`);
        });
    }
  },

  updateSelectedQuestionId: async function (qid) {
    const currentSelectedQuestionId =
      store.getState().interactions.selectedInteractionId;
    const selectedTextId = store.getState().texts.selectedTextId;
    try {
      const updateStr = new Date().toISOString();

      store.dispatch(setSelectedQuestion(qid));
      store.dispatch(setSelectedInteractionId(qid));

      const docRef = doc(
        firestore,
        `sq3r/${auth.currentUser.uid}/texts/${selectedTextId}`
      );
      await updateDoc(docRef, {
        grQuestionId: qid,
        updatedAt: updateStr
      });
    } catch (err) {
      captureException(
        err,
        `"Failed to update selected question id. user: ${auth.currentUser.uid}, questionId: ${qid} `
      );
      store.dispatch(setSelectedQuestion(currentSelectedQuestionId));
      store.dispatch(setSelectedInteractionId(currentSelectedQuestionId));
    }
  },
  updateSelectedStep: async function (step, prevStep) {
    const selectedTextId = store.getState().texts.selectedTextId;

    try {
      store.dispatch(setStage({ stage: step }));
      const updateStr = new Date().toISOString();
      if (prevStep !== step) {
        const docRef = doc(
          firestore,
          `sq3r/${auth.currentUser.uid}/texts/${selectedTextId}`
        );
        await updateDoc(docRef, {
          grStage: step,
          updatedAt: updateStr
        });
      }
    } catch (err) {
      captureException(err);
    }
  },
  submitGrTask: async function (
    submission_id,
    text_id,
    taskId,
    courseId,
    questions,
    answers
  ) {
    try {
      store.dispatch(
        updateTaskSubmissionStatus({
          id: submission_id,
          status: "Submitted",
          submission_date: new Date().toJSON()
        })
      );
      await httpCallables.taskFunctions({
        func_name: "updateGrTaskSubmission",
        submission_id,
        task_id: taskId,
        questions,
        answers
      });
      store.dispatch(
        addSnackbar({
          message: {
            id: "tasks.submitted",
            defaultMessage: "Task submitted successfully"
          },
          actions: [
            {
              intlId: "undo",
              intlDefaultMsg: "undo",
              callBack: "undoSubmitGr"
            },
            {
              intlId: "task.submission.continue",
              intlDefaultMsg: "Continue work in reader",
              callBack: "goToReader"
            }
          ],
          data: {
            submission_id,
            text_id,
            taskId
          }
        })
      );
      navigationService.navigate(`/tasks?course_id=${courseId}`);
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to submit task, user: ${auth.currentUser.uid} submissionId: ${submission_id}`
      );
      return false;
    }
  },
  undoGrTaskSubmission: async function (submission_id) {
    try {
      store.dispatch(
        updateTaskSubmissionStatus({
          id: submission_id,
          status: "Pending",
          submission_date: null
        })
      );
      await httpCallables.taskFunctions({
        func_name: "undoGrTaskSubmission",
        submission_id
      });
      store.dispatch(
        addSnackbar({
          message: {
            id: "tasks.submission.canceled",
            defaultMessage: "Assignment submission canceled"
          }
        })
      );
    } catch (err) {
      captureException(
        err,
        `Failed to un-submit gr task, user: ${auth.currentUser.uid} submissionId: ${submission_id}`
      );
      return false;
    }
  }
};

export default grAPI;
