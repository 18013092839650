import { firestore, auth } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import store from "../redux/store";
import { setTasksViewed } from "../redux/userSlice";
import { captureException } from "../utils/errorHandlers";
import { log as innerLog } from "../utils/user-log";
import { add } from "date-fns";

const userAPI = {
  updateUser: async function (data) {
    try {
      const userRef = doc(firestore, `users/${auth.currentUser.uid}`);
      await updateDoc(userRef, { ...data });
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to update user data. user ${auth.currentUser.uid}`
      );
      return false;
    }
  },

  updateViewedTasks: async function (taskId) {
    const tasksViewed = [...store.getState().user.tasksViewed];
    try {
      const userRef = doc(firestore, `users/${auth.currentUser.uid}`);
      await updateDoc(userRef, {
        tasksViewed: tasksViewed.concat([taskId])
      });
      // we update the store because we haven't set a listener to user doc changes.
      store.dispatch(setTasksViewed(tasksViewed.concat([taskId])));
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to update viewed tasks. user ${auth.currentUser.uid}`
      );
      return false;
    }
  },

  log: async function (data, shouldAwait = false) {
    if (shouldAwait) {
      await innerLog(data);
    } else innerLog(data);
  },

  logAction: async function (data, shouldAwait = false) {
    const now = new Date();

    const fullRecord = {
      user_id: auth.currentUser.uid,
      created_at: now,
      ttl: add(now, { months: 1 }),
      ...data
    };
    this.log(fullRecord, shouldAwait);
  }
};

export default userAPI;
