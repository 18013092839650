import { firestore, auth, httpCallables } from "../firebase";
import { doc, setDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { createAction } from "@reduxjs/toolkit";
import { captureException } from "../utils/errorHandlers";
import { REDUX_ACTIONS } from "../consts";

// This is a dummy action that only logs user navigation actions
export const userNavigated = createAction(REDUX_ACTIONS.USER_NAVIGATED);

export const updateHighlights = createAction("updateHighlights");

export const updateSq3r = createAction("updateSq3r");
export const updateTask = createAction("updateTask");
const gotSq3r = createAction("gotSq3r");

export const resetNotificationBedge = createAction("resetNotificationBedge");

//firestore.doc("/texts/14").set({"questions":[{"question":"מה הסוגיה המרכזית בטקסט?","text":"מה הסוגיה המרכזית בטקסט?","id":1},{"text":"מה עמדת המחבר בנוגע לסוגיה זאת?","question":"מה עמדת המחבר בנוגע לסוגיה זאת?","id":2},{"text":"מהן הטענות המרכזיות בטקסט?","question":"מהן הטענות המרכזיות בטקסט?","id":3},{"text":"כיצד מדגימ/ה המחבר/ת את הטענות?","id":4,"question":"כיצד מדגימ/ה המחבר/ת את הטענות?"},{"id":5,"text":"מהם המושגים או הדמויות המרכזיים בטקסט?","question":"מהם המושגים או הדמויות המרכזיים בטקסט?"}]});
const listenerUnsubscribeList = [];

export function fetchFirebase(storeAPI, text_id) {
  if (auth.currentUser) {
    listenerUnsubscribeList.forEach((a) => a && a());
    listenerUnsubscribeList.splice(0, listenerUnsubscribeList.length);

    if (text_id > 0) {
      // SQ3R handling
      const sq3rRef = doc(
        firestore,
        `sq3r/${auth.currentUser.uid}/texts/${text_id}`
      );
      const unsubscribeSq3r = onSnapshot(
        sq3rRef,
        (snapshot) => {
          if (snapshot.exists()) {
            storeAPI.dispatch(
              gotSq3r({ ...snapshot.data(), text_id: text_id })
            );
          } else {
            const emptyGEdoc = {
              updatedAt: new Date().toISOString(),
              highlights: [],
              questions: [],
              status: "Active",
              grMode: "light",
              grStage: 0,
              grQuestionId: false,
              grShowAnswers: false,
              grShowHighlights: false
            };
            setDoc(sq3rRef, emptyGEdoc);
          }
        },
        (error) => {
          captureException(error, `Failed to fetch SQ3R from firebase`);
        }
      );
      listenerUnsubscribeList.push(unsubscribeSq3r);
    }
  }
  auth.onAuthStateChanged(function (user) {
    if (!user) {
      listenerUnsubscribeList.forEach((a) => a && a());
      listenerUnsubscribeList.splice(0, listenerUnsubscribeList.length);
    }
  });
}

function firebaseMiddleware(storeAPI) {
  return function wrapDispatch(next) {
    return async function handleAction(action) {
      if (!action) return;

      if (action.type === "user/setAuth") {
        //unsubscribe from all collections
        fetchFirebase(storeAPI, 0);
      }

      if (action.type === "texts/setSelectedTextId") {
        fetchFirebase(storeAPI, action.payload);
        //set userActions
      }
      if (action.type === "texts/setPangeaText") {
        fetchFirebase(storeAPI, action.payload.id, action.payload);
      }

      if (
        storeAPI.getState().user &&
        storeAPI.getState().user.original_auth === -1
      ) {
        if (action.type === "updateHighlights") {
          const highlightsRef = doc(
            firestore,
            `highlights/${auth.currentUser.uid}/texts/${action.payload.text_id}`
          );
          setDoc(highlightsRef, {
            highlights: action.payload.highlights,
            updatedAt: new Date().toISOString()
          });
        }
        if (action.type === "updateSq3r") {
          const reduxState = storeAPI.getState();
          const currentUser = auth.currentUser;
          const status = action.payload.status ?? reduxState.gr.status;
          const sq3rRef = doc(
            firestore,
            `sq3r/${currentUser.uid}/texts/${action.payload.text_id || reduxState.texts.selectedTextId}`
          );

          updateDoc(sq3rRef, {
            updatedAt: new Date().toISOString(),
            highlights:
              action.payload.highlights ||
              reduxState.interactions.highlights ||
              [],
            questions:
              action.payload.questions ||
              reduxState.interactions.questions ||
              [],
            status
          }).catch((err) => {
            captureException(err);
          });
        }
        if (action.type === "user/setProfile") {
          const uid = auth?.currentUser?.uid;
          if (uid) {
            const userRef = doc(firestore, `users/${uid}`);
            setDoc(userRef, action.payload);
          }
        }
        if (action.type === "user/setPersistent") {
          const uid = auth?.currentUser?.uid;
          if (uid) {
            const keys = Object.keys(action.payload);
            await Promise.all(
              keys.map(async (key) => {
                const docRef = doc(firestore, `users/${uid}/persistent/${key}`);
                await setDoc(docRef, action.payload[key]);
              })
            );
          }
        }
        if (action.type === "updateTask") {
          const reduxState = storeAPI.getState();
          const selectedTaskId = reduxState.tasks.selectedTaskId;
          const currentUser = auth.currentUser;
          const taskRef = doc(
            firestore,
            `tasks/${currentUser.uid}/task/${selectedTaskId}`
          );

          const {
            step = reduxState.task.step,
            selectedQuestion = reduxState.task.selectedQuestionIndex,
            highlightViewOn = reduxState.task.highlightViewOn
          } = action.payload;

          const updateObj = {
            status: "Active",
            step,
            selectedQuestion,
            highlightViewOn,
            updatedAt: new Date().toISOString()
          };

          updateDoc(taskRef, updateObj);
          httpCallables.setCoursesStatus({ task: action.payload.taskId });
        }
      }
      // Do anything here: pass the action onwards with next(action),
      // or restart the pipeline with storeAPI.dispatch(action)
      // Can also use storeAPI.getState() here

      try {
        return next(action);
      } catch (err) {
        // Trying to investigate -
        // https://pangea-ai.sentry.io/issues/4806353491/events/5ada00396c404aa6ac6389eb9c2cc04d/?project=6487416&referrer=replay-errors

        captureException(
          err,
          `Couldn't dispatch action ${JSON.stringify(action)}`
        );
        return next();
      }
    };
  };
}

export default firebaseMiddleware;
