import scrollIntoView from "scroll-into-view-if-needed";

export function scrollPageAndThumbnailIntoView(pageNumber, options = {}) {
  scrollPageIntoView(pageNumber, options);
  scrollThumbnailIntoView(pageNumber, options);
}

export function scrollPageIntoView(pageNumber, options = {}) {
  const pageNode = document.querySelector(`[data-page-number='${pageNumber}']
  `);
  if (!pageNode) return;
  scrollIntoView(pageNode, {
    behavior: options.behavior || "auto",
    block: options.block || "center",
    inline: options.inline || "center"
  });
}

export function scrollAnnotationIntoView(annotationId, options = {}) {
  const node = document.querySelector(`[data-id='${annotationId}']
  `);
  if (!node) return;

  scrollIntoView(node, {
    behavior: "auto",
    block: options.block || "center",
    inline: options.inline || "center"
  });
}

export function scrollThumbnailIntoView(pageNumber, options = {}) {
  const pageNode = document.querySelector(
    `[data-thumbnail-number='${pageNumber}']`
  );
  if (!pageNode) return;
  scrollIntoView(pageNode, {
    behavior: options.behavior || "auto",
    block: options.block || "center",
    inline: options.inline || "center"
  });
}

export const determinePDFPagePosition = (
  currentPageVisibility,
  previousPageVisibility,
  nextPageVisibility
) => {
  const onlyOnePageVisible = !nextPageVisibility && !previousPageVisibility;

  if (currentPageVisibility > 50 || onlyOnePageVisible) return "center";
  else if (nextPageVisibility) return "end";
  else if (previousPageVisibility) return "start";
};

export function getPDFTextSelection(selection) {
  if (!selection || selection.rangeCount === 0) {
    return null;
  }

  const range = selection.getRangeAt(0);

  // Function to find the last text node in an element
  function findLastTextNodeInElement(element) {
    // Use TreeWalker to find all text nodes
    const walker = document.createTreeWalker(
      element,
      NodeFilter.SHOW_TEXT,
      null,
      false
    );

    let last = null;
    let node;
    while ((node = walker.nextNode())) {
      last = node;
    }
    return last;
  }

  // Function to find the previous text node, searching through all previous siblings and their children
  function findPreviousTextNode(container, currentIndex) {
    for (let i = currentIndex - 1; i >= 0; i--) {
      const node = container.childNodes[i];

      if (node.nodeType === Node.TEXT_NODE) {
        return node;
      }

      if (node.nodeType === Node.ELEMENT_NODE) {
        const textNode = findLastTextNodeInElement(node);
        if (textNode) {
          return textNode;
        }
      }
    }
    return null;
  }

  // Function to get the correct node at a specific offset
  function getNodeAtOffset(container, offset) {
    if (container.nodeType === Node.TEXT_NODE) {
      return { node: container, offset: offset };
    }

    const child = container.childNodes[offset];
    if (!child) {
      // If offset is out of bounds, return the last text node
      const lastTextNode = findPreviousTextNode(
        container,
        container.childNodes.length
      );
      return lastTextNode
        ? { node: lastTextNode, offset: lastTextNode.length }
        : null;
    }

    if (child.nodeType === Node.TEXT_NODE) {
      return { node: child, offset: 0 };
    }

    // For BR or other element nodes, get the previous text node
    const previousTextNode = findPreviousTextNode(container, offset);
    return previousTextNode
      ? { node: previousTextNode, offset: previousTextNode.length }
      : null;
  }

  // Get the actual start and end nodes with their correct offsets
  const startInfo = getNodeAtOffset(range.startContainer, range.startOffset);
  const endInfo = getNodeAtOffset(range.endContainer, range.endOffset);

  if (!startInfo || !endInfo) {
    return null;
  }

  // Create a range with the correct nodes and offsets
  const correctedRange = document.createRange();
  correctedRange.setStart(startInfo.node, startInfo.offset);
  correctedRange.setEnd(endInfo.node, endInfo.offset);

  return {
    startNode: startInfo.node,
    endNode: endInfo.node,
    startOffset: startInfo.offset,
    endOffset: endInfo.offset,
    correctedRange: correctedRange,
    selectedText: correctedRange.toString()
  };
}
