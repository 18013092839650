import { createSlice, createSelector } from "@reduxjs/toolkit";
import { shallowEqual } from "react-redux";
import { setProfile } from "./userSlice";

export function setSelectedCourseId(courseId) {
  return (dispatch, getState) => {
    const state = getState();
    const userPorfile = {
      ...state.user.userProfile,
      selectedCourseId: Number(courseId)
    };
    dispatch(setProfile(userPorfile));
  };
}

const initialState = {
  courses: [],
  didSetCourses: false // There is a more elegant solution, but this does the job without complicated QA
};
export const coursesSlice = createSlice({
  name: "courses",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase("user/setAuth", (state, value) => {
        // TODO: check if we need this
        state.courses = [];
        state.didSetCourses = false;
      })
      .addCase("user/logout", () => initialState); // TODO: check if we need this
  },
  reducers: {
    setCourses: (state, value) => {
      state.courses = value.payload;
      state.didSetCourses = true;
    },
    addCourse: (state, value) => {
      state.courses.push(value.payload);
      state.didSetCourses = true;
    }
  }
});

export const { setCourses, addCourse } = coursesSlice.actions;

export const selectCourse = createSelector(
  [(state) => state.courses.courses, (state, course_id) => course_id],
  (courses, course_id) =>
    courses.find((course) => course.id === course_id) || {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectSelectedCourse = createSelector(
  (state) => state.courses.courses,
  (state) => state.user.userProfile.selectedCourseId,
  (courses, selectedCourseId) =>
    courses.find((course) => course.id === selectedCourseId) ?? {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectCourseRole = createSelector(
  (state) => state.courses.courses,
  (state) => state.user.userProfile.selectedCourseId,
  (courses, selectedCourseId) =>
    courses.find((course) => course.id === selectedCourseId)?.course_role,
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectCourseByTextId = createSelector(
  // selectCurrentCourseTimezone returns the timezone of the current selected course
  (state) => state.texts.texts,
  (state) => state.courses.courses,
  (state) => state.texts.selectedTextId,
  (texts, courses, text_id) => {
    const text = texts.find((text) => text.id === text_id) || {};
    return courses.find((course) => course.id === text.course_id) || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectCourseByTaskId = createSelector(
  // selectCurrentCourseTimezone returns the timezone of the current selected course
  (state) => state.tasks.tasks,
  (state) => state.courses.courses,
  (state, taskId) => taskId,
  (tasks, courses, taskId) => {
    const task = tasks.find((task) => task.id === Number(taskId)) || {};
    return courses.find((course) => course.id === task.course_id) || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export default coursesSlice.reducer;
