import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: {
    uid: null,
    email: null,
    emailVerified: false,
    displayName: null,
    isAnonymous: false,
    photoURL: null,
    providerData: [],
    stsTokenManager: {},
    createdAt: null,
    lastLoginAt: null,
    apiKey: null,
    appName: null,
    isEmpty: true,
    isLoaded: false
  },
  authError: null,
  isInitializing: false,
  errors: null
};

const firebaseSlice = createSlice({
  name: "firebase",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      if (action.payload) {
        const {
          uid,
          email,
          emailVerified,
          displayName,
          isAnonymous,
          photoURL,
          providerData,
          stsTokenManager,
          createdAt,
          lastLoginAt,
          apiKey,
          appName
        } = action.payload;
        state.auth = {
          uid,
          email,
          emailVerified,
          displayName,
          isAnonymous,
          photoURL,
          providerData,
          stsTokenManager,
          createdAt,
          lastLoginAt,
          apiKey,
          appName,
          isEmpty: false,
          isLoaded: true
        };
      } else {
        state.auth = {
          ...initialState.auth,
          isLoaded: true
        };
      }
    },
    clearAuth: (state) => {
      state.errors = initialState.auth;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    clearError: (state) => {
      state.errors = null;
    }
  }
});

export const { setAuth, clearAuth, setErrors, clearError } =
  firebaseSlice.actions;
export default firebaseSlice.reducer;
