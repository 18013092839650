import { navigationService } from "../utils/navigation";

export const routerMiddleware = (store) => (next) => (action) => {
  if (action.type === "router/NAVIGATE") {
    const { to, options } = action.payload;
    navigationService.navigate(to, options);
    return;
  }

  if (action.type === "router/REPLACE") {
    const { to, options } = action.payload;
    navigationService.replace(to, options);
    return;
  }

  return next(action);
};

// Action creators for routing
export const push = (to, options) => ({
  type: "router/NAVIGATE",
  payload: { to, options }
});

export const replace = (to, options) => ({
  type: "router/REPLACE",
  payload: { to, options }
});
