// Dependencies
import {
  fetchMentor,
  mapStateToFetchProps,
  orchestrateMentorResponse
} from "./utils";

// redux
import store from "../../store";
import { reFetchSubmission } from "../../tasksSlice";
import {
  incomingMessage,
  outgoingMessage,
  sessionLoaded,
  undoMessage
} from "../../chatSlice";
import { addSnackbar } from "../../snackbarSlice";

// rxJs
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";

import {
  tap,
  map,
  withLatestFrom,
  concatMap,
  catchError,
  mergeMap
} from "rxjs/operators";
import { captureException } from "../../../utils/errorHandlers";

export const outgoingMessageEpic = (action$, state$) => {
  return action$.pipe(
    ofType(outgoingMessage.type),
    withLatestFrom(state$),
    map(([action, state]) => mapStateToFetchProps(action, state)),
    mergeMap((props) =>
      fetchMentor(props, store).pipe(
        tap(
          // would be better to handle this as an action not a side effect
          ({ newAssetsSaved, submissionId, interaction }) => {
            newAssetsSaved &&
              store.dispatch(
                reFetchSubmission({
                  submission_id: submissionId ? Number(submissionId) : null,
                  text_id: interaction.text_id
                })
              );
          }
        ),
        concatMap(({ interaction, interactionNumber }) => {
          // in this case the sessionId is the interaction_id which points to the anchor interaction
          const sessionId = interaction.interaction_id;
          return orchestrateMentorResponse(
            interaction,
            sessionId,
            interactionNumber
          );
        }),
        catchError((error) => {
          const { sessionId, currentUserResp } = props;
          captureException(error, "Error in initMentorEpic");

          store.dispatch(
            addSnackbar({
              message: {
                id: "outgoingMentorEpic.error",
                defaultMessage: "Error in chat, contact us for details."
              }
            })
          );
          if (currentUserResp?.trim?.()?.length <= 3) {
            store.dispatch(
              addSnackbar({
                message: {
                  id: "outgoingMentorEpic.short",
                  defaultMessage: "Please provide a longer response."
                }
              })
            );
          }
          // Reset state
          store.dispatch(sessionLoaded({ sessionId }));
          store.dispatch(undoMessage(props));
          return EMPTY;
        })
      )
    ),
    map((payload) => ({ type: incomingMessage.type, payload })),
    catchError((error) => {
      captureException(error, "Error in outgoingMessageEpic");
      return EMPTY;
    })
  );
};
