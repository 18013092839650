import { captureException } from "../utils/errorHandlers";
import { createSelector } from "@reduxjs/toolkit";
import {
  FEATURES,
  PRIMARY_SIDEBAR_STATE,
  SECONDARY_SIDEBAR_STATE
} from "../consts";
import { shallowEqual } from "react-redux";
// Selectors

export const selectFirebaseFetchStatus = createSelector(
  [(state) => state],
  (state) => state.firestore.status.requested
);

export const selectConfig = createSelector(
  [
    (state) => state,
    (state) => state.router.location.pathname,
    (state, feature) => feature
  ],
  (state, pathname, feature = null) => {
    if (!feature) feature = determineFeatureByPathname(pathname);
    return determineConfig(state, feature);
  }
);

export const selectDarkMode = createSelector(
  selectConfig,
  (config) => config?.darkMode
);

export const selectLocale = createSelector(
  selectConfig,
  (config) => config?.locale || "en"
);

export const selectAlertsDuration = createSelector(
  selectConfig,
  (config) => config?.alertsDuration
);

export const selectAvatar = createSelector(selectConfig, (config) => {
  return config?.photoURL;
});

export const selectTextDirection = createSelector(
  [(state) => state.firestore.data.defaultConfig],
  (config) => config?.textDirection || "ltr"
);

export const selectPrimarySidebarState = createSelector(
  // returns a string of the current state
  selectConfig,
  (config) => config?.primarySidebarState
);

export const selectIsPrimarySidebar = createSelector(
  // returns a bool that indicated if the sidebar is displayed
  selectConfig,
  (config) => config?.primarySidebarState === PRIMARY_SIDEBAR_STATE.EXPANDED
);

export const selectSecondarySidebarState = createSelector(
  // returns a string of the current state
  selectConfig,
  (config) => config?.secondarySidebarState
);

export const selectSecondarySidebarCollapsed = createSelector(
  // returns a bool that indicated if the sidebar is displayed
  selectConfig,
  (config) =>
    config?.secondarySidebarState !== SECONDARY_SIDEBAR_STATE.COLLAPSED
);

export const selectIsThumbnails = createSelector(
  selectConfig,
  (config) =>
    config?.secondarySidebarState === SECONDARY_SIDEBAR_STATE.THUMBNAILS
);

export const selectIsComments = createSelector(
  selectConfig,
  (config) => config?.secondarySidebarState === SECONDARY_SIDEBAR_STATE.COMMENTS
);
export const selectIsSuggestionsInSidebar = createSelector(
  selectConfig,
  (config) =>
    config?.secondarySidebarState === SECONDARY_SIDEBAR_STATE.SUGGESTIONS
);
export const selectIsSuggestions = createSelector(
  [(state) => state.firestore.data.crossFeatureConfig],
  (config) => config?.suggestions || false
);
export const selectAcceptedTermsOfUse = createSelector(
  [selectConfig, selectFirebaseFetchStatus],
  (config, fetchStatus) => {
    // As long as the crossFeatureConfig was not fetched we are returning true...
    // ... so that the terms dialog wont open until we get the user's profile
    if (!fetchStatus?.crossFeatureConfig) return true;
    else return config?.acceptedTermsOfUse || false;
  }
);

// Comments Privacy State

export const selectCommentsPrivacy = createSelector(
  selectConfig,
  (config) => config?.privacy || "ME_ONLY"
);

// Text Position

export const selectBookmarkedPosition = createSelector(
  [
    (state, _text_id) => state.firestore.data?.textLocations,
    (_state, text_id) => text_id
  ],
  (textLocations, text_id) => {
    if (!textLocations) return {};
    return textLocations[text_id] || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectIsFeatureFlagEnabled = createSelector(
  [selectConfig, (state, feature) => feature],
  (config, feature) => config?.featureFlags?.[feature] || false
);

// Utils

export function determineConfig(state, feature = null) {
  const defaultConfig = state.firestore.data?.defaultConfig;
  const customConfig = state.firestore.data?.customConfig;
  const crossFeatureConfig = state.firestore.data?.crossFeatureConfig;
  const commentsCustomConfig = state.firestore.data?.commentsCustomConfig;
  const pathname = state.router.location.pathname;
  if (!feature) feature = determineFeatureByPathname(pathname);

  return _determineConfig(
    defaultConfig,
    crossFeatureConfig,
    customConfig,
    commentsCustomConfig,
    feature
  );
}

function _determineConfig(
  defaultConfig = {},
  crossFeatureConfig = {},
  customConfig = {},
  commentsCustomConfig = {},
  feature
) {
  try {
    const featureFlags = determineFeatureFlags(
      defaultConfig,
      crossFeatureConfig
    );

    return {
      ...featureFlags,
      ...defaultConfig[FEATURES.CROSS_FEATURE],
      ...defaultConfig[feature],
      ...crossFeatureConfig,
      ...commentsCustomConfig,
      ...customConfig
    };
  } catch (err) {
    captureException(
      err,
      `determineConfig: could not get config for feature ${feature}`
    );
  }
}

export function determineFeatureFlags(defaultConfig, crossFeatureConfig) {
  const featureFlags = {
    ...defaultConfig?.FEATURE_FLAGS,
    ...crossFeatureConfig?.FEATURE_FLAGS
  };
  return { featureFlags };
}

export function determineFeatureByPathname(path) {
  switch (path) {
    case "/reader":
      return FEATURES.READER;
    case "/notifications":
      return FEATURES.READER;
    case "/task":
      return FEATURES.TASK;
    default:
      return FEATURES.DEFAULT;
  }
}

export const selectCollection = (state, path) => state.firestore.data[path];
export const selectDoc = (state, collection, id) =>
  state.firestore.data[collection]?.[id];
export const selectOrdered = (state, path) => state.firestore.ordered[path];
