import { firestore, auth } from "../firebase";
import { httpCallables } from "../firebase";
import { v4 as uuid } from "uuid";
import {
  createAnswer,
  deleteAnswer,
  setSelectedInteractionId
} from "../redux/interactionsSlice";
import { setIsLoading } from "../redux/readerActionsSlice";
import { updateTaskSubmissionStatus } from "../redux/tasksSlice";
import { setStep } from "../redux/taskSlice";
import { addSnackbar } from "../redux/snackbarSlice";
import store from "../redux/store";
import { captureException } from "../utils/errorHandlers";
import { navigationService } from "../utils/navigation";
import { doc, setDoc } from "firebase/firestore";

const standardTaskAPI = {
  createAnswerComment: async function (answer) {
    if (!answer) return;
    const id = uuid();
    try {
      store.dispatch(createAnswer({ id, ...answer }));
      const answerForDB = {
        func_name: "createAnswerComment",
        id,
        ...answer
      };
      httpCallables.interactionFunctions(answerForDB);
    } catch (err) {
      captureException(err);
      store.dispatch(deleteAnswer(answer));
    }
  },
  createAnswerSelection: async function (answer) {
    if (!answer) return;
    const id = uuid();
    try {
      store.dispatch(createAnswer({ id, ...answer }));
      const answerForDB = {
        id,
        ...answer,
        func_name: "createAnswerSelection"
      };
      httpCallables.interactionFunctions(answerForDB);
    } catch (err) {
      captureException(err);
      store.dispatch(deleteAnswer(answer));
    }
  },

  undoStandardTaskSubmission: async function (submission_id, offline_task_id) {
    try {
      store.dispatch(setIsLoading(true));
      await httpCallables
        .taskFunctions({
          func_name: "undoStandardTaskSubmission",
          offline_task_id,
          submission_id
        })
        .then(({ data }) => {
          const { success } = data;

          if (success) {
            store.dispatch(
              updateTaskSubmissionStatus({
                id: submission_id,
                status: "Active",
                submission_date: null
              })
            );
            navigationService.navigate(`/task?submission_id=${submission_id}`);
          } else {
            store.dispatch(
              addSnackbar({
                message: {
                  id: "undo.undoSubmissionFailed",
                  defaultMessage: "Undo failed" // TODO: need text from product
                }
              })
            );
            let error = new Error(`UNDO_TASK_SUBMISSION_FAILED`);
            error.message = data.error;
            error.data = { submission_id };
            throw error;
          }
        });
    } catch (err) {
      captureException(err);
    } finally {
      store.dispatch(setIsLoading(false));
    }
  },

  allowResubmitSubmission: async function (
    submission_id,
    student_uid,
    task_name
  ) {
    try {
      await httpCallables
        .taskFunctions({
          func_name: "allowResubmitSubmission",
          submission_id,
          student_uid,
          task_name
        })
        .then(({ data }) => {
          const { success } = data;

          if (success) {
            store.dispatch(
              updateTaskSubmissionStatus({
                id: submission_id,
                status: "Active",
                submission_date: null
              })
            );
            store.dispatch(
              addSnackbar({
                message: {
                  id: "tasks.manager.resubmitted",
                  defaultMessage: "Resubmission enabled"
                }
              })
            );
          } else {
            store.dispatch(
              addSnackbar({
                message: {
                  id: "undo.undoSubmissionFailed",
                  defaultMessage: "Undo failed" // TODO: need text from product
                }
              })
            );
            let error = new Error(`RESUBMIT_SUBMISSION_FAILED`);
            error.message = data.error;
            error.data = { submission_id };
            throw error;
          }
        });
    } catch (err) {
      captureException(err);
    }
  },

  updateSelectedQuestionId: async function (
    questionId,
    taskId,
    firestoreQuestionId
  ) {
    const currentInteractionId =
      store.getState().interactions.selectedInteractionId;
    try {
      store.dispatch(setSelectedInteractionId(questionId));
      if (firestoreQuestionId != questionId) {
        const updateStr = new Date().toISOString();
        const docRef = doc(
          firestore,
          `tasks/${auth.currentUser.uid}/task/${taskId}`
        );
        setDoc(
          docRef,
          { selectedQuestion: questionId, updatedAt: updateStr },
          { merge: true }
        );
      }
    } catch (err) {
      captureException(err);
      store.dispatch(setSelectedInteractionId(currentInteractionId));
    }
  },

  updateSelectedStep: async function (step, taskId, prevStep) {
    try {
      store.dispatch(setStep(step));
      const updateStr = new Date().toISOString();
      if (prevStep !== step) {
        const docRef = doc(
          firestore,
          `tasks/${auth.currentUser.uid}/task/${taskId}`
        );
        setDoc(docRef, { step: step, updatedAt: updateStr }, { merge: true });
      }
    } catch (err) {
      captureException(err);
    }
  }
};

export default standardTaskAPI;
