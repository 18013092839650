import * as Sentry from "@sentry/react";

export function shouldLogToErrorMonitor() {
  return process.env.NODE_ENV !== "development" || window?.activateSentry;
}

export function captureException(err, message) {
  if (shouldLogToErrorMonitor()) {
    Sentry.captureException(err, (scope) => {
      if (message) {
        scope.addBreadcrumb({
          type: "error",
          category: "error",
          level: "error",
          message: message
        });
      }
    });
  } else console.error(message, err);
}

export function addBreadcrumb(category, message, level = "info") {
  if (category && message) {
    Sentry.addBreadcrumb({
      category,
      message,
      level
    });
  }
}

export function captureMessage(text) {
  if (shouldLogToErrorMonitor()) Sentry.captureMessage(text);
  else console.log(text);
}
