import { createSlice, createSelector, current } from "@reduxjs/toolkit";
import { selectCurrentTask } from "./tasksSlice";
import ePub from "epubjs";
import { uniqBy } from "lodash-es";
import { findIndexToInsert } from "../utils/text-utils";
import {
  USER_TYPE,
  INTERACTION_SUBTYPES,
  INTERACTION_TYPES,
  TASK,
  USER_PROFILE,
  FEATURES
} from "../consts";
import { shallowEqual } from "react-redux";
import { captureException } from "../utils/errorHandlers";
import { updateDocument } from "./firestoreSlice";

function mapInteractionToBucket(interaction) {
  const { interaction_type: type, interaction_subtype: subtype } = interaction;
  // TODO: there are currently type: CONTAINER and THEME that are not handled here
  if (type === "QUESTION") return "questions";
  else if (type === "SUGGESTION") return "suggestions";
  else if (type === "REASON") return "reasons";
  else if (subtype === "QUOTE") return "highlights";
  else if (type === "ANSWER") return "answers";
  else if (type === "FEEDBACK") return "feedbacks";
  else if (type === "REVIEW") return "reviews";
  else if (type === "COMMENT") return "comments";
}

export const STATUSES = {
  PENDING: "PENDING",
  IDLE: "IDLE"
};

const initialState = {
  selectedInteractionId: null,
  questions: [],
  highlights: [],
  answers: [],
  feedbacks: [],
  reviews: [],
  comments: [],
  suggestions: [],
  reasons: [],
  selectedInteractionForChat: {},
  selectedSuggestion: {},
  newQuestion: false,
  selectedTempHighlight: null
};

export const interactionsSlice = createSlice({
  name: "interactions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      "realtimeInteractions/resetThreadsState",
      (state, value) => {
        return initialState;
      }
    );
  },
  reducers: {
    setSelectedInteractionId: (state, value) => {
      state.selectedInteractionId = value.payload;
    },

    setInteractions: (state, value) => {
      // map the interactions to buckets
      const buckets = value.payload.reduce((accumulator, current) => {
        try {
          const bucket = mapInteractionToBucket(current);
          if (!accumulator[bucket]) accumulator[bucket] = [];
          if (current.interaction_type === INTERACTION_TYPES.SUGGESTION) {
            current.color = "#BBDEFB";
            accumulator[bucket].push(current);
          } else accumulator[bucket].push(current);
        } catch (error) {
          captureException(
            error,
            `Failed to map interaction to bucket. type: ${current.interaction_type}, subtype: ${current.interaction_subtype}`
          );
        }
        return accumulator;
      }, {});

      // update the state with the new buckets
      Object.keys(buckets).forEach((bucket) => {
        state[bucket] = buckets[bucket];
      });
    },

    updateInteraction: (state, value) => {
      // Optimistic UI: we're synchronously updating the state while updateInteractionEpic handles updating the server and rolling back if needed
      const { interaction: interactionToUpdate, update } = value.payload;
      const bucket = mapInteractionToBucket(interactionToUpdate);
      const index = state[bucket].findIndex(
        (interaction) => interaction.id === interactionToUpdate.id
      );

      state[bucket][index] = {
        ...interactionToUpdate,
        ...update,
        status: STATUSES.PENDING
      };
    },

    interactionUpdatedSuccessfully: (state, value) => {
      // interaction was updated successfully on the server, so we can update the state to reflect that

      const { interaction: interactionToUpdate } = value.payload;
      const bucket = mapInteractionToBucket(interactionToUpdate);

      const index = state[bucket].findIndex(
        (interaction) => interaction.id === interactionToUpdate.id
      );

      state[bucket][index]["status"] = STATUSES.IDLE;
    },

    deleteInteraction: (state, value) => {
      const { id, interactionType } = value.payload;
      state[interactionType] = state[interactionType].filter(
        (interaction) => interaction.id !== id
      );
    },
    resetQuestions: (state, value) => {
      state.questions = [];
    },
    deleteQuestion: (state, value) => {
      state.questions = state.questions.filter((q) => q.id !== value.payload);
      state.highlights = state.highlights.filter(
        (h) => h.interaction_id !== value.payload
      );
      state.answers = state.answers.filter(
        (h) => h.interaction_id !== value.payload
      );
    },

    addHighlight: (state, value) => {
      const highlight = { ...value.payload };
      let insertAt = findIndexToInsert(state.highlights, highlight);
      highlight.order = insertAt;

      state.highlights.splice(insertAt, 0, value.payload);
      state.highlights.map((h, i) => {
        if (i > insertAt) h.order = i;
      });
    },
    deleteHighlight: (state, value) => {
      const id = value.payload;
      state.highlights = state.highlights.filter(
        (highlight) => highlight.id !== id
      );
    },
    updateHighlight: (state, value) => {
      state.highlights = state.highlights.map((h) =>
        h.id === value.payload.id ? value.payload : h
      );
    },
    removeTempHighlight: (state, value) => {
      // remove temp highlight (without interaction_id) from state
      const id = value.payload;
      state.highlights = state.highlights.filter(
        (highlight) => highlight.id !== id
      );
      state.selectedTempHighlight = null;
    },
    createQuestion: (state, value) => {
      state.questions.push(Object.assign(value.payload));
    },
    createAnswer: (state, value) => {
      state.answers.push(value.payload);
    },
    createReview: (state, value) => {
      state.reviews.push(value.payload);
    },
    deleteReview: (state, value) => {
      const { id } = value.payload;
      state.reviews = state.reviews.filter((feedback) => feedback.id !== id);
    },
    createFeedback: (state, value) => {
      state.feedbacks.push(value.payload);
    },
    updateFeedbacks: (state, value) => {
      state.feedbacks = value.payload;
    },
    deleteFeedback: (state, value) => {
      const { id } = value.payload;
      state.feedbacks = state.feedbacks.filter(
        (feedback) => feedback.id !== id
      );
    },
    createComment: (state, value) => {
      state.comments.push(value.payload);
    },
    deleteComment: (state, value) => {
      const { id } = value.payload;
      state.comments = state.comments.filter((comment) => comment.id !== id);
    },
    updateComment: (state, value) => {
      const { id } = value.payload;
      state.comments = state.comments.map((comment) =>
        comment.id === id ? value.payload : comment
      );
    },
    createInteraction: (state, value) => {
      const {
        id,
        content,
        interactionId = null,
        text_id = null,
        interactionType,
        order,
        title,
        points,
        user_type,
        interaction_type,
        interaction_subtype,
        rich_text
      } = value.payload;

      state[interactionType].push({
        id,
        content,
        interaction_id: interactionId,
        text_id: text_id,
        order,
        title,
        points,
        rich_text,
        user_type,
        interaction_type,
        interaction_subtype
      });
    },
    setInteractionsOrder: (state, value) => {
      const { order, interactionType } = value.payload;
      const staticInteractions = state[interactionType].filter(
        (interaction) => !order.includes(interaction.id)
      );
      const newOrder = order.map((id, index) => {
        const currentInteraction = state[interactionType].find(
          (interaction) => interaction.id === id
        );
        return { ...currentInteraction, order: index };
      });
      state[interactionType] = [...staticInteractions, ...newOrder];
    },
    setSelectedInteractionForChat: (state, value) => {
      state.selectedInteractionForChat = value.payload;
    },
    setSelectedSuggestion: (state, value) => {
      state.selectedSuggestion = value.payload;
    },
    addNewQuestion: (state, value) => {
      state.newQuestion = value.payload;
    },
    setSelectedTempHighlight: (state, value) => {
      state.selectedTempHighlight = value.payload;
    },
    updateHighlightsColorByQuestionId: (state, value) => {
      const { interactionId, color } = value.payload;
      state.highlights = state.highlights.map((h) => {
        return h.interaction_id === interactionId
          ? { ...h, color: color, status: STATUSES.PENDING }
          : h;
      });
    }
  }
});

export const {
  setSelectedInteractionId,
  setInteractions,
  createInteraction,
  updateInteraction,
  interactionUpdatedSuccessfully,
  updateInteractionId,
  deleteInteraction,
  resetQuestions,
  addHighlight,
  deleteHighlight,
  updateHighlight,
  removeTempHighlight,
  createQuestion,
  createAnswer,
  deleteAnswer,
  createReview,
  deleteReview,
  createFeedback,
  updateFeedbacks,
  deleteFeedback,
  createComment,
  deleteComment,
  updateComment,
  deleteQuestion,
  setInteractionsOrder,
  setSelectedInteractionForChat,
  setSelectedSuggestion,
  addNewQuestion,
  setSelectedTempHighlight,
  updateHighlightsColorByQuestionId
} = interactionsSlice.actions;

// Selectors
export const selectQuestionAnswers = createSelector(
  [(state) => state.interactions.answers, (state, questionId) => questionId],
  (answers, questionId) => {
    const questionAnswers = filterAnswersByQuestionId(answers, questionId);
    const groupedByUser = groupAnswersByUser(questionAnswers);
    const userAnswers = Object.values(groupedByUser)
      .map(selectAnswer)
      .filter((answer) => answer && answer.content !== "");
    return userAnswers;
  }
);

export const selectAnswerComment = createSelector(
  [(state) => state.interactions.answers, (state, questionId) => questionId],
  (answers, questionId) => {
    const filteredAnswers = answers.filter(
      (answer) => answer.interaction_id === questionId
    );

    return (
      filteredAnswers.find((answer) => answer.content) ||
      filteredAnswers[0] ||
      {}
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectQuestionHighlights = createSelector(
  [(state) => state.interactions.highlights, (state, questionId) => questionId],
  (highlights, questionId) => {
    const filteredHighlights = [...highlights]
      .filter((highlight) => highlight.interaction_id === questionId)
      .sort((a, b) => {
        if (a?.order !== undefined && b?.order !== undefined)
          return sortByOrder(a, b);
        else return sortByCfi(a, b);
      });
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const selectPeerReviewQuestion = createSelector(
  [
    (state) => state.interactions.questions,
    (state, task_id) => Number(task_id)
  ],
  (questions, task_id) =>
    questions.find((question) => question.task_id === task_id) || {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

//TODO: this needs a better name that describes why we are getting the questions without submission ID
export const selectQuestions = createSelector(
  [(state) => state.interactions.questions, (state, task_id) => task_id],
  (questions, task_id) => {
    return [...questions]
      .filter((question) => question.task_id === Number(task_id))
      .filter((question) => !question.submission_id)
      .sort((a, b) => sortByOrder(a, b));
  }
);

export const selectSubmissionQuestions = createSelector(
  [(state) => state.interactions.questions],
  (questions) => {
    return [...questions].sort((a, b) => sortByUserTypeAndOrder(a, b));
  }
);

export const selectGrQuestions = createSelector(
  (state) => state.interactions.questions,
  (questions) => {
    return [...questions]
      .filter((question) => question.interaction_type === "QUESTION")
      .filter(
        (question) => question.question_context === TASK.TYPE.GUIDED_READING
      );
  }
);

export const selectUserGrQuestions = createSelector(
  selectGrQuestions,
  (questions) =>
    [...questions]
      .filter(
        (question) =>
          question.user_type == USER_TYPE.STUDENT ||
          !!question.submission_id ||
          !question.task_id
      ) // using loose equlity becouse the initial UI update returns undefind when the DB returns null
      .sort((a, b) => sortByOrder(a, b))
);

export const selectGrTaskQuestions = createSelector(
  selectGrQuestions,
  (questions) => {
    return [...questions]
      .filter(
        (question) =>
          question.user_type == USER_TYPE.TEACHER &&
          !question.submission_id &&
          question.task_id
      ) // using loose equlity becouse the initial UI update returns undefind when the DB returns null
      .sort((a, b) => sortByOrder(a, b));
  }
);

export const selectReaderHighlights = createSelector(
  (state) => state.interactions.highlights,
  (highlights) => {
    const filteredHighlights = [...highlights]
      .filter((highlight) => highlight.interaction_type === "READER")
      .sort((a, b) => {
        if (a?.order && b?.order) return sortByOrder(a, b);
        else return sortByCfi(a, b);
      });
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const selectTextHighlights = createSelector(
  (state) => state.interactions.highlights,
  (state) => state.interactions.answers,
  (highlights, answers) => {
    const filteredHighlights = [...highlights, ...answers];
    // return uniqBy(filteredHighlights, "cfi");
    return filteredHighlights;
  }
);
export const selectGrStepOneHighlights = createSelector(
  (state) => state.interactions.highlights,
  (highlights) => {
    const filteredHighlights = [...highlights]
      .filter(
        (highlight) =>
          // exclude READER highlights
          highlight.interaction_type === "ANSWER" &&
          !highlight.interaction_id &&
          !highlight.task_id
      )
      .sort((a, b) => sortByOrder(a, b));
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const selectCurrentTaskHighlights = createSelector(
  (state) => state.interactions.highlights,
  selectCurrentTask,
  (highlights, task) => {
    const filteredHighlights = highlights
      .filter(
        (highlight) =>
          highlight.task_id === task.id &&
          highlight.user_type !== USER_TYPE.MENTOR
      )
      .sort((a, b) => sortByOrder(a, b));
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const selectedQuestionHighlights = createSelector(
  (state) => state.interactions.highlights,
  (state) => state.interactions.selectedInteractionId,
  (highlights, questionId) => {
    const filteredHighlights = [...highlights]
      .filter(
        (highlight) =>
          highlight.interaction_id === questionId &&
          highlight.user_type !== USER_TYPE.MENTOR
      )
      .sort((a, b) => {
        if (
          Object.prototype.hasOwnProperty.call(a, "order") &&
          Object.prototype.hasOwnProperty.call(b, "order")
        )
          return sortByOrder(a, b);
        else return sortByCfi(a, b);
      });
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const questionHighlights = createSelector(
  [(state) => state.interactions.highlights, (state, questionId) => questionId],
  (highlights, questionId) => {
    const filteredHighlights = [...highlights]
      .filter(
        (highlight) =>
          highlight.interaction_id === questionId &&
          highlight.user_type !== USER_TYPE.MENTOR
      )
      .sort((a, b) => {
        if (a?.order && b?.order) return sortByOrder(a, b);
        else return sortByCfi(a, b);
      });
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const selectCurrentGrStepHighlights = createSelector(
  [
    (state) => state.interactions.highlights,
    (state) =>
      isNaN(state.gr.stage)
        ? state.gr.stage
        : ["highlight", "review", "answer"][state.gr.stage],
    (state) => state.gr.selectedQuestionId
  ],
  (highlights, step, questionId) => {
    if (step === 0) {
      const filteredHighlights = [...highlights]
        .filter((highlight) => !highlight.interaction_id)
        .sort((a, b) => sortByOrder(a, b));
      return uniqBy(filteredHighlights, "cfi");
    } else if (step === 1) {
      const filteredHighlights = [...highlights]
        .filter((highlight) => highlight.interaction_id === questionId)
        .sort((a, b) => sortByOrder(a, b));
      return uniqBy(filteredHighlights, "cfi");
    } else return [];
  }
);

// TODO: selectCurrentQuestion and selectCurrentInteraction are redundent
// Since we want to save the state of every part of the app seperatly, we won't be able to use selectedInteractionId. We need to refactor this and save a seperate attribute for, grQuestion, task question ect.

export const selectCurrentQuestion = createSelector(
  (state) => state.interactions.questions,
  (state) => state.interactions.selectedInteractionId,
  (questions, questionId) => {
    return questions.find((question) => question.id === questionId) || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectCurrentInteraction = createSelector(
  (state) => state.interactions.questions,
  (state) => state.interactions.selectedInteractionId,
  (questions, questionId) => {
    return questions.find((question) => question.id === questionId) || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectSubmissionFeedback = createSelector(
  [
    (state) => state.interactions.feedbacks,
    (state, submission_id) => Number(submission_id)
  ],
  (feedbacks, submission_id) => {
    return (
      feedbacks
        .filter((feedback) => !feedback.interaction_id)
        .find((feedback) => feedback.submission_id === submission_id) || {}
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectTaskFeedbacks = createSelector(
  [(state) => state.interactions.feedbacks, (state, task_id) => task_id],
  (feedbacks, task_id) => {
    return feedbacks.filter((feedback) => feedback.task_id === task_id);
  }
);

export const selectSubmissionTaskFeedback = createSelector(
  [
    (state) => state.interactions.feedbacks,
    (state, submission_id) => submission_id
  ],
  (feedbacks, submission_id) => {
    return (
      feedbacks
        .filter((feedback) => feedback.submission_id === submission_id)
        .find(
          (feedback) =>
            feedback.interaction_subtype === INTERACTION_SUBTYPES.TASK
        ) || {}
    );
  }
);

export const selectQuestionFeedback = createSelector(
  [selectCurrentInteraction, (state) => state.interactions.feedbacks],
  (question, feedbacks) => {
    return (
      feedbacks.filter((feedback) => feedback.interaction_id === question.id) ||
      []
    );
  }
);

export const selectTeacherQuestionFeedback = createSelector(
  [selectQuestionFeedback],
  (questionFeedbacks) => {
    return (
      questionFeedbacks.find(
        (feedback) => feedback.user_type === USER_TYPE.TEACHER
      ) || {}
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectMentorQuestionFeedback = createSelector(
  [selectQuestionFeedback],
  (questionFeedbacks) => {
    return (
      questionFeedbacks.find(
        (feedback) => feedback.user_type === USER_TYPE.MENTOR
      ) || {}
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectInteractionHighlight = createSelector(
  (state) => state.interactions.highlights,
  (state, interactionId) => interactionId,
  (highlights, interactionId) => {
    return highlights.filter(
      (highlight) => highlight.interaction_id === interactionId
    );
  }
);

export const selectPeerReviewReflection = createSelector(
  [
    (state) => state.interactions.answers,
    (state, submission_id) => submission_id
  ],
  (answers, submission_id) => {
    return (
      answers.find((answer) => answer.submission_id === submission_id) || {}
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectPeerReviewReview = createSelector(
  [
    (state) => state.interactions.reviews,
    (state, submission_id) => submission_id
  ],
  (reviews, submission_id) => {
    return (
      reviews.find((review) => review.submission_id === submission_id) || {}
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectPeerReviewReply = createSelector(
  [
    (state) => state.interactions.comments,
    (state, submission_id) => submission_id
  ],
  (comments, submission_id) => {
    return (
      comments.find((comment) => comment.submission_id === submission_id) || {}
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectSuggestionsByQuestionId = createSelector(
  [
    (state) => state.interactions.suggestions,
    (state) => state.interactions.selectedInteractionId
  ],
  (suggestions, selectedInteractionId) => {
    let filteredSuggestions = suggestions
      .filter((suggestion) => {
        return suggestion.interaction_id === selectedInteractionId;
      })
      .sort((a, b) => {
        return sortByCfi(a, b);
      });
    return filteredSuggestions;
  }
);
export const findCurrentSuggestionIndex = createSelector(
  [
    (state) => state.interactions.suggestions,
    (state) => state.interactions.selectedSuggestion,
    (state) => state.interactions.selectedInteractionId
  ],
  (suggestions, selectedSuggestion, selectedInteractionId) => {
    const filteredSuggestions = suggestions.filter((suggestion) => {
      return suggestion.interaction_id === selectedInteractionId;
    });
    if (!filteredSuggestions.length) return 0;
    const index = filteredSuggestions
      .sort((a, b) => sortByCfi(a, b))
      .findIndex((obj) => obj.id === selectedSuggestion.id);
    if (index < 0) return filteredSuggestions.length - 1;
    else return index;
  }
);

export const selectedReasonBySuggestionId = createSelector(
  [
    (state) => state.interactions.selectedSuggestion,
    (state) => state.interactions.reasons
  ],
  (selectedSuggestion, reasons) => {
    if (!Object.hasOwn(selectedSuggestion, "id")) return reasons[0];
    const selectedReason = reasons.find(
      (reason) => reason.interaction_id === selectedSuggestion.id
    );
    return selectedReason;
  }
);

export const selectSubmitValidation = createSelector(
  [
    (state) => state.interactions.questions,
    (state) => state.interactions.answers,
    (state) => state.interactions.highlights
  ],
  (questions, answers, highlights) => {
    const validate = [];
    questions.forEach((question, index) => {
      const questionHighlights = highlights.filter(
        (item) => item.interaction_id === question.id
      );
      const questionAnswer = answers.find(
        (item) => item.interaction_id === question.id
      );
      let validation = {
        highlight: true,
        answer: true
      };

      switch (question.interaction_subtype) {
        case "FIND_IN_TEXT":
          if (!questionHighlights.length) {
            validation.highlight = false;
          }
          break;
        case "OPEN_ENDED":
          if (!questionHighlights?.length && question.includeCitation) {
            validation.highlight = false;
          }
          if (
            !questionAnswer ||
            (questionAnswer.rich_text && questionAnswer.rich_text === "<p></p>")
          ) {
            validation.answer = false;
          }
          break;
        case "MULTI_CHOICE":
          if (!questionHighlights?.length && question.includeCitation) {
            validation.highlight = false;
          }
          if (!questionAnswer) {
            validation.answer = false;
          }
          break;
        default:
          break;
      }
      validate.push(validation);
    });
    return validate;
  }
);

// Utils
export function sortByOrder(a, b) {
  if (!("order" in a) || a.order > b.order) return 1;
  else if (!("order" in b) || a.order < b.order) return -1;
  else return 0;
}

function sortByCfi(a, b) {
  let EpubCFI = new ePub.CFI();

  try {
    return EpubCFI.compare(a.cfi, b.cfi);
  } catch (err) {
    console.log("Error compring ", { a, b });
    return 0;
  }
}

function sortByUserTypeAndOrder(a, b) {
  if (!("order" in a)) return 1;

  // Sort by user type (teacher first) and order
  if (a.user_type === b.user_type) return a.order - b.order;
  else if (a.user_type === USER_TYPE.TEACHER) return -1;
  else if (a.user_type !== USER_TYPE.TEACHER) return 1;
}

export default interactionsSlice.reducer;

const filterAnswersByQuestionId = (answers, questionId) =>
  answers.filter((answer) => answer.interaction_id === questionId);

const groupAnswersByUser = (questionAnswers) =>
  questionAnswers.reduce((group, answer) => {
    const { user_uid } = answer;
    group[user_uid] = group[user_uid] ?? [];
    group[user_uid].push(answer);
    return group;
  }, {});

const selectAnswer = (answers) => {
  if (answers.length > 1) {
    return (
      answers.find(
        (answer) =>
          answer.content !== "" && answer.user_type === USER_TYPE.STUDENT
      ) ||
      answers.find(
        (answer) =>
          answer.content !== "" && answer.user_type === USER_TYPE.MENTOR
      )
    );
  }
  return answers[0];
};

function validateGR(questionHighlights, questionAnswer) {
  let highlight = true;
  let answer = true;
  if (!questionHighlights?.length) {
    highlight = false;
  }
  if (
    !questionAnswer ||
    (questionAnswer.rich_text.blocks &&
      !questionAnswer.rich_text.blocks.reduce((acc, block) => {
        return (acc += block.text);
      }, "").length)
  ) {
    answer = false;
  }
  return {
    highlight,
    answer
  };
}

export function setInteractionColor(interactionId, color) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.firebase.auth.uid;
    try {
      const path = `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.INTERACTIONS}/${interactionId}/color`;
      const payload = { color };
      dispatch(updateDocument(path, payload));
    } catch (err) {
      captureException(err, `setInteractionColor failed. user: ${userId}`);
    }
  };
}
