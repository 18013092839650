import { firestore, auth } from "../firebase";
import { doc, setDoc, collection } from "firebase/firestore";
import { captureException } from "./errorHandlers";

export const log = async function (data) {
  try {
    const appActionsRef = collection(firestore, "appActions");

    // We are using the await conditionally because the log doesn't get the auth on logout
    // TODO: once the logout flow is refactored ...
    // ... we should find a better way to run callbacks before logout
    return setDoc(doc(appActionsRef), data).catch((err) => {
      if (auth?.currentUser?.uid) {
        captureException(
          err,
          `Failed to log user behaviour. user ${auth?.currentUser?.uid}`
        );
      }
    });
  } catch (err) {
    if (auth?.currentUser?.uid) {
      captureException(
        err,
        `Failed to log user behaviour. user ${
          auth?.currentUser?.uid
        } , data = ${JSON.stringify(data)}`
      );
    }
  }
};
