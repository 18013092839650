import { createSlice, createSelector } from "@reduxjs/toolkit";
import { shallowEqual } from "react-redux";

const initialState = {
  texts: [],
  selectedTextId: null,
  selectedText: null
};

export const textsSlice = createSlice({
  name: "texts",
  initialState,
  extraReducers: (builder) => {
    builder.addCase("user/logout", () => initialState);
  },
  reducers: {
    // NEW
    setTexts: (state, value) => {
      state.texts = value.payload;
    },
    setSelectedTextId: (state, value) => {
      state.selectedTextId = value.payload;
    },
    setTextUrl: (state, value) => {
      const { url, text_id } = value.payload;
      let textIndex = state.texts.findIndex((text) => text.id === text_id);
      state.texts[textIndex] = { ...state.texts[textIndex], url };
    },
    reorderTexts: (state, value) => {
      const texts = value.payload;

      const updatedTexts = texts.map((text, index) => {
        return { ...text, display_priority: index };
      });
      updatedTexts.forEach((updatedText) => {
        const index = state.texts.findIndex(
          (text) => text.id === updatedText.id
        );
        state.texts[index] = updatedText;
      });
    },
    // OLD
    setPangeaText: (state, value) => {
      let text_id = 0;
      if (value.payload.text_id) {
        text_id = value.payload.text_id;
      } else {
        text_id = value.payload.id;
      }
      state.selectedTextId = Number(text_id);
      state.selectedText = {
        ...value.payload
      };
    },
    setSelectedText: (state, value) => {
      state.selectedTextId = Number(value.payload);
      state.selectedText = state.texts.filter(
        (el) => el.id === value.payload
      )[0];
    },
    removeText: (state, value) => {
      state.texts = state.texts.filter((el) => el.id !== value.payload);
    },
    addText: (state, value) => {
      state.texts.push(value.payload);
    },

    undoCreateText: (state, value) => {
      const { text: textToUndo } = value.payload;
      state.texts = state.texts.filter((text) => text.id !== textToUndo.id);
    }
  }
});

export const {
  //NEW
  setTexts,
  setSelectedTextId,
  setTextUrl,
  reorderTexts,
  undoCreateText,
  // OLD
  addText,
  setSelectedText,
  removeText,
  setPangeaText,
  setTextLocation
} = textsSlice.actions;

export const selectTexts = createSelector(
  [(state) => state.texts.texts, (state, course_id) => Number(course_id)], //TODO: change texts to Texts when done with the refactor
  (texts, course_id) => {
    if (!course_id) return texts;
    const unsortedTexts = texts.filter((text) => text.course_id === course_id);

    return unsortedTexts.sort((a, b) => {
      if (a.display_priority > b.display_priority) return 1;
      else if (b.display_priority > a.display_priority) return -1;
      else return 0;
    });
  }
);

export const selectText = createSelector(
  //Takes in a text id and return a text or an empty obj
  [(state) => state.texts.texts, (state, text_id) => text_id],
  (texts, text_id) => texts.find((text) => text.id === text_id) || {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectCurrentText = createSelector(
  //Takes in a text id and return a text or an empty obj
  [(state) => state.texts.texts, (state) => state.texts.selectedTextId],
  (texts, text_id) => texts.find((text) => text.id === text_id) || {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const isPdfSelector = createSelector(
  [(state) => state.texts.texts, (state) => state.texts.selectedTextId],
  (texts, text_id) =>
    texts
      .find((text) => text.id === text_id)
      ?.file_url?.toLowerCase()
      .endsWith(".pdf") || false
);

export default textsSlice.reducer;
