import { httpCallables } from "../firebase";
import store from "../redux/store";
import { reorderTexts } from "../redux/textsSlice";
import { captureException } from "../utils/errorHandlers";

const textsAPI = {
  deleteCourseText: async function (data) {
    try {
      await httpCallables.deleteCourseText(data);
      return true;
    } catch (err) {
      captureException(err, `Failed to delete course text`);
      return false;
    }
  },
  reorderCourseTexts: async function (texts, courseId) {
    try {
      store.dispatch(reorderTexts(texts));
      return httpCallables.reorderCourseTexts({
        course: courseId,
        texts: texts
      });
    } catch (err) {
      captureException(err, `Failed to reorder texts`);
    }
  }
};

export default textsAPI;
