import { auth, httpCallables } from "../firebase";
import { signInWithCustomToken } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setOriginalUser } from "../redux/userSlice";
import { useNavigate, useLocation } from "react-router";
import { setSelectedCourseId } from "../redux/coursesSlice";

function useImpersonate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pathname, search } = useLocation();

  const originalAuth = useSelector((state) => state.user.original_auth);

  function impersonate(user) {
    dispatch(setSelectedCourseId(false));

    httpCallables
      .adminFunctions({ func_name: "impersonate", uid: user })
      .then((response) => {
        if (response.data && response.data.length) {
          //Save the url to get back to once stoping the impersonate
          localStorage.setItem(
            "impersonate.getBackToUrl",
            `${pathname}/${search}`
          );

          auth.currentUser.getIdTokenResult(true).then((idTokenResult) => {
            dispatch(setOriginalUser(idTokenResult.token));
            localStorage.setItem(
              "impersonate.originalUser",
              idTokenResult.token
            );
            signInWithCustomToken(auth, response.data).then(() => {
              navigate("/");
            });
          });
        }
      });
  }

  function stopImpersonate() {
    httpCallables
      .adminFunctions({ func_name: "stopImpersonate", token: originalAuth })
      .then((response) => {
        signInWithCustomToken(auth, response.data).then((userCredential) => {
          let user = userCredential.user;
          dispatch(setOriginalUser(-1));
          localStorage.removeItem("impersonate.originalUser");
          dispatch(
            setAuth({
              displayName: user.displayName,
              photoURL: user.photoURL,
              uid: user.uid,
              email: user.email
            })
          );

          const url = localStorage.getItem("impersonate.getBackToUrl");
          navigate(url || `/admin`);
        });
      });
  }

  return { impersonate, stopImpersonate };
}

export default useImpersonate;
