import { httpCallables, auth } from "../firebase";
import store from "../redux/store";
import { v4 as uuid } from "uuid";
import {
  setInteractionsOrder,
  deleteQuestion,
  createAnswer,
  deleteAnswer,
  addHighlight,
  deleteHighlight,
  createQuestion,
  updateHighlight,
  updateHighlightsColorByQuestionId
} from "../redux/interactionsSlice";
import { addSnackbar } from "../redux/snackbarSlice";
import { captureException } from "../utils/errorHandlers";
import { INTERACTION_TYPES, INTERACTION_SUBTYPES, USER_TYPE } from "../consts";

const interactionsAPI = {
  createQuestion: async function (question, text_id, courseId, options = {}) {
    const _options = Object.assign(
      {
        highlights: [],
        summary: "",
        afterReduxCallback: () => {}
      },
      options
    );
    const id = uuid();
    store.dispatch(
      createQuestion({
        id,
        interaction_type: INTERACTION_TYPES.QUESTION,
        interaction_subtype: INTERACTION_SUBTYPES.OPEN_ENDED,
        user_type: USER_TYPE.STUDENT,
        ...question
      })
    );
    _options.afterReduxCallback(id);
    try {
      const questionForDataBase = { ...question };
      delete questionForDataBase.user_type;

      await httpCallables.interactionFunctions({
        func_name: "createQuestion",
        id,
        ...questionForDataBase,
        text_id,
        course_id: courseId
      });
      this.createAnswer(_options.summary);
      _options.highlights.forEach((h) => {
        this.createHighlight(h, text_id, courseId);
      });
      return { id };
    } catch (err) {
      captureException(
        err,
        `Failed to create guided reading question. user ${auth.currentUser.uid}`
      );
      store.dispatch(deleteQuestion(id));
      return false;
    }
  },
  deleteQuestion: async function (question, highlights, summary) {
    try {
      store.dispatch(deleteQuestion(question.id));
      await httpCallables.interactionFunctions({
        func_name: "deleteInteraction",
        id: question.id
      });
      store.dispatch(
        addSnackbar({
          message: {
            id: "delete.question",
            defaultMessage: "Question deleted"
          },
          actions: [
            {
              intlId: "undo",
              intlDefaultMsg: "undo",
              callBack: "createQuestion"
            }
          ],
          data: {
            question,
            highlights,
            summary
          }
        })
      );
      return true;
    } catch (err) {
      // Rollback
      store.dispatch(createQuestion(question));
      store.dispatch(
        addSnackbar({
          message: {
            id: "errorMessgaes.deleteQuestion",
            defaultMessage: "Problem deleting question"
          }
        })
      );
      captureException(
        err,
        `Failed to delete guided reading question. user ${auth.currentUser.uid}, questionId:${question.id}`
      );
      return false;
    }
  },
  createHighlight: async function (highlight, text_id, courseId) {
    const id = uuid();
    try {
      store.dispatch(
        addHighlight({
          id,
          ...highlight,
          text_id: text_id,
          course_id: courseId,
          interaction_type: highlight.interaction_type,
          interaction_subtype: highlight.interaction_subtype
        })
      );
      const highlightForDB = { ...highlight };
      httpCallables.interactionFunctions({
        func_name: "createInteraction",
        id,
        ...highlightForDB,
        text_id: text_id,
        interaction_type: highlight.interaction_type,
        interaction_subtype: highlight.interaction_subtype,
        course_id: courseId
      });
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to create guided reading highlight. user ${auth.currentUser.uid}`
      );

      //TODO: alert user
      store.dispatch(deleteHighlight(id)); // Rollback
      return false;
    }
  },
  createHighlightFromTemp: async function (id, questionId) {
    // updating temp highlight with interaction_id and update db
    try {
      const highlight = store
        .getState()
        .interactions.highlights?.find((h) => h.id === id);
      const highlightColor =
        store.getState().readerActions.persistentActionState.highlightColor;
      if (highlight) {
        const updatedHighlight = {
          ...highlight,
          interaction_id: questionId,
          color: highlightColor
        };
        store.dispatch(updateHighlight(updatedHighlight));
        httpCallables.interactionFunctions({
          func_name: "createInteraction",
          ...updatedHighlight
        });
        return true;
      }
      return false;
    } catch (err) {
      captureException(
        err,
        `Failed to create highlight from exist id. user ${auth.currentUser.uid}`
      );

      //TODO: alert user
      store.dispatch(deleteHighlight(id)); // Rollback
      return false;
    }
  },
  deleteHighlight: async function (highlight, showSnackbar = true) {
    try {
      store.dispatch(deleteHighlight(highlight.id));
      await httpCallables.interactionFunctions({
        func_name: "deleteInteraction",
        id: highlight.id
      });
      showSnackbar &&
        store.dispatch(
          addSnackbar({
            message: {
              id: "delete.card",
              defaultMessage: "Card deleted"
            },
            actions: [
              {
                intlId: "undo",
                intlDefaultMsg: "undo",
                callBack: "createHighlight"
              }
            ],
            data: {
              highlight
            }
          })
        );
      return true;
    } catch (err) {
      // Rollback
      showSnackbar &&
        store.dispatch(
          addSnackbar({
            message: {
              id: "error.deleteFaild",
              defaultMessage:
                "There was a problem deleting the card, please try again"
            }
          })
        );
      store.dispatch(addHighlight(highlight));
      captureException(
        err,
        `Failed to delete guided reading highlight. user ${auth.currentUser.uid}, questionId:${highlight.id}`
      );
      return false;
    }
  },
  createAnswer: async function (answer) {
    if (!answer) return;
    const id = uuid();
    try {
      store.dispatch(createAnswer({ id, ...answer }));
      const answerForDB = {
        func_name: "createAnswerComment",
        id,
        ...answer
      };
      return httpCallables.interactionFunctions(answerForDB);
    } catch (err) {
      store.dispatch(deleteAnswer(answer));
      captureException(
        err,
        `Failed to create guided reading answer. user ${auth.currentUser.uid}`
      );
    }
  },
  updateQuestionOrder: async function (order, rollback) {
    try {
      store.dispatch(
        setInteractionsOrder({ order, interactionType: "questions" })
      );
      return httpCallables.interactionFunctions({
        order,
        func_name: "updateInteractionsOrder"
      });
    } catch (err) {
      captureException(
        err,
        `Failed to update guided reading questions order. user ${auth.currentUser.uid}`
      );
      if (rollback)
        store.dispatch(
          setInteractionsOrder({ rollback, interactionType: "questions" })
        );
    }
  },
  updateHighlightsOrder: async function (order, rollback) {
    try {
      store.dispatch(
        setInteractionsOrder({ order, interactionType: "highlights" })
      );
      httpCallables.interactionFunctions({
        func_name: "updateInteractionsOrder",
        order
      });
    } catch (err) {
      captureException(
        err,
        `Failed to update higlihgts order. user ${auth.currentUser.uid}`
      );
      if (rollback)
        store.dispatch(
          setInteractionsOrder({ rollback, interactionType: "highlights" })
        );
    }
  },
  updateInteractionsOrder: async function (interactionType, order, rollback) {
    try {
      store.dispatch(setInteractionsOrder({ type: interactionType, order }));
      httpCallables.interactionFunctions({
        func_name: "updateInteractionsOrder",
        order
      });
    } catch (err) {
      captureException(
        err,
        `Failed to update interactions order. user ${auth.currentUser.uid}`
      );
      if (rollback) store.dispatch(setInteractionsOrder(rollback));
    }
  },
  updateHighlightsColorByQuestionId: async function (
    interactionUUID,
    interactionId,
    color
  ) {
    try {
      httpCallables.interactionFunctions({
        func_name: "updateHighlightsColorByQuestionId",
        interactionId,
        color
      });
      store.dispatch(
        updateHighlightsColorByQuestionId({
          interactionId: interactionUUID,
          color
        })
      );
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to update highlights color by question id. user ${auth.currentUser.uid}`
      );
      return false;
    }
  }
};

export default interactionsAPI;
