import { createSlice } from "@reduxjs/toolkit";
import { initMentor } from "./chatSlice";
import { FAB_STYLE_VIEW, REDUX_ACTIONS } from "../consts";

export const FAB_STATUS = {
  HIDDEN: "HIDDEN",
  PENDING: "PENDING",
  IDLE: "IDLE"
};

export const FAB_TYPE = {
  MENTOR_READER: "MENTOR_READER",
  MENTOR_REVIEW: "MENTOR_REVIEW"
};

const initialState = {
  status: FAB_STATUS.HIDDEN,
  type: FAB_TYPE.MENTOR_REVIEW,
  fabViewStyle: FAB_STYLE_VIEW.EXPANDED
};

export const fabSlice = createSlice({
  name: "fab",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(initMentor, (state, value) => {
        state.status = FAB_STATUS.HIDDEN;
      })
      .addCase(REDUX_ACTIONS.LOCATION_CHANGED, () => initialState);
  },
  reducers: {
    showFab: (state, value) => {
      const { type } = value.payload;

      state.type = type;
      state.status = FAB_STATUS.IDLE;
    },
    hideFab: (state) => {
      state.status = FAB_STATUS.HIDDEN;
    },
    setFabStatus: (state, value) => {
      const { status } = value.payload;
      state.status = status;
    },
    setFabViewStyle: (state, value) => {
      state.fabViewStyle = value.payload;
    },

    // Actions for epics
    mentorFabClicked: () => {}
  }
});

export const {
  showFab,
  hideFab,
  setFabStatus,
  setFabViewStyle,

  // actions for epics
  mentorFabClicked
} = fabSlice.actions;

// Selectors
// Utils

export default fabSlice.reducer;
