import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  textId: null,
  pageNum: 1,
  totalPages: 0,
  width: 0,
  zoom: 1,
  currentPage: 1,
  shouldShowLocation: null,
  documentIsReady: false,
  thumbnailsAreReady: false,
  pages: {}
};

// Thunk

export const pdfSlice = createSlice({
  name: "pdf",
  initialState,
  extraReducers: (builder) => {
    builder.addCase("texts/setSelectedTextId", (state, value) => {
      if (value.payload !== state.textId) {
        state.pageNum = 1;
        state.textId = value.payload;
        state.totalPages = null;
        state.zoom = 1;
        state.shouldShowLocation = null;
      }
    });
  },
  reducers: {
    resetDocument: (state, value) => {
      return initialState;
    },
    //TODO: this is currently not going to work
    navigateToPageNumber: (state, value) => {
      state.currentPage = value.payload;
      // Q: not sure about this, this should be a product decision
      state.zoom = 1;
    },
    setShouldShowLocation: (state, value) => {
      state.shouldShowLocation = value.payload;
    },
    setPdfPageNum: (state, value) => {
      state.pageNum = value.payload;
    },
    setPdfTotalPages: (state, value) => {
      const totalPages = value.payload;
      const pages = Array.from(Array(totalPages)).reduce(
        (accumulator, current, i) => {
          accumulator[i + 1] = {
            visibility: 0,
            renderMode: "none"
          };
          return accumulator;
        },
        {}
      );
      state.pages = pages;
      state.totalPages = value.payload;
    },
    setPdfZoom: (state, value) => {
      state.zoom = value.payload;
    },
    setPDFThumbnails: (state, value) => {
      state.thumbnails = value.payload;
    },
    setCurrentPage: (state, value) => {
      state.currentPage = value.payload;
    },
    setPageVisibility: (state, value) => {
      const { pageNumber, visibility } = value.payload;
      state.pages[pageNumber].visibility = visibility;

      const updatedCurrentPage = Object.keys(state.pages).reduce(
        (accumulator, current) => {
          const page = state.pages[current];

          if (page.visibility > state.pages[accumulator].visibility)
            accumulator = current;
          return accumulator;
        },
        1
      );

      if (updatedCurrentPage !== state.currentPage) {
        state.currentPage = Number(updatedCurrentPage);
      }
    },
    setPageRenderMode: (state, value) => {
      const { pageNumber, renderMode } = value.payload;
      state.pages[pageNumber].renderMode = renderMode;
    },
    setHighlightsRendered: (state, value) => {
      const { pageNumber } = value.payload;
      state.pages[pageNumber].highlightsRendered = true;
    },
    setPageIsLoaded: (state, value) => {
      const pageNumber = value.payload;
      state.pages[pageNumber].isLoaded = true;

      // TODO: optimaize this with a counter
      const pendingPages = Object.values(state.pages).filter(
        (page) => !page.isLoaded
      ).length;

      if (!pendingPages) state.documentIsReady = true;
    },
    setThumbnailsAreReady: (state, value) => {
      state.thumbnailsAreReady = value.payload;
    }
  }
});

export const selectZoom = (state) => state.pdf.zoom;

export const selectPageVisibility = createSelector(
  [(state) => state.pdf.pages, (state, pageNumber) => pageNumber],
  (pages, pageNumber) => pages[pageNumber]?.visibility || 0
);

export const selectPageRenderMode = createSelector(
  [(state) => state.pdf.pages, (state, pageNumber) => pageNumber],
  (pages, pageNumber) => pages[pageNumber]?.renderMode || "none"
);

export const selectPageHighlightsRendered = createSelector(
  [(state) => state.pdf.pages, (state, pageNumber) => pageNumber],
  (pages, pageNumber) => {
    return pages[pageNumber]?.highlightsRendered || false;
  }
);

export const {
  resetDocument,
  setPdfPageNum,
  setPdfTotalPages,
  setPdfZoom,
  setShouldShowLocation,
  navigateToNextPage,
  navigateToPreviusPage,
  navigateToPageNumber,
  setPDFThumbnails,
  setCurrentPage,
  setPageVisibility,
  setPageRenderMode,
  setPageIsLoaded,
  setHighlightsRendered,
  setThumbnailsAreReady
} = pdfSlice.actions;

export default pdfSlice.reducer;
