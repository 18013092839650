import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false
};

export const emailModalSlice = createSlice({
  name: "emailModal",
  initialState,
  reducers: {
    setIsOpen: (state, value) => {
      state.isOpen = value.payload;
    }
  }
});

export const { setIsOpen } = emailModalSlice.actions;

export default emailModalSlice.reducer;
