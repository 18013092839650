import { firestore } from "../firebase";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  writeBatch
} from "firebase/firestore";
import store from "../redux/store";

const notificationsAPI = {
  markAsRead: async (notificationId) => {
    const state = store.getState();
    const currentUserUid = state.firebase.auth.uid;

    const notificationRef = doc(
      firestore,
      `notifications/${currentUserUid}/userNotifications/${notificationId}`
    );

    await updateDoc(notificationRef, {
      isRead: true,
      includeInCounter: false
    }).catch((error) => {
      console.error("Error marking notification as read:", error);
    });
  },
  markAllAsRead: async () => {
    const state = store.getState();
    const currentUserUid = state.firebase.auth.uid;

    const batch = writeBatch(firestore);
    try {
      const notificationsRef = collection(
        firestore,
        `notifications/${currentUserUid}/userNotifications`
      );

      const querySnapshot = await getDocs(notificationsRef);
      querySnapshot.forEach((document) => {
        const docRef = doc(
          firestore,
          `notifications/${currentUserUid}/userNotifications/${document.id}`
        );
        batch.update(docRef, {
          isRead: true,
          includeInCounter: false
        });
      });

      await batch.commit();
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  }
};

export default notificationsAPI;
