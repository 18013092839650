import React from "react";

import { captureException } from "../../../../utils/errorHandlers";
import { useState, useEffect } from "react";
import { onSnapshot, collection, updateDoc, doc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { Box, Button } from "@mui/material";
import { ACCEPTENCE_STATUS } from "./consts";
import { useQuery } from "../../../../hooks";
import { httpCallables } from "../../../../firebase";
import { useDispatch } from "react-redux";
import { addSnackbar } from "../../../../redux/snackbarSlice";
import { useIntl } from "react-intl";
import { fetchTeacherCourseTasks } from "../../../../redux/tasksSlice";
import { setTexts } from "../../../../redux/textsSlice";
import Banner from "../../../SharedComponents/Banner";

const ExportBanner = ({ bannerType, setBannerShown }) => {
  const firestore = useFirestore();
  const { course_id } = useQuery();
  const dispatch = useDispatch();
  const intl = useIntl();

  const courseMaterialRef = collection(firestore, "course_material");
  const [courseMaterialData, setCourseMaterialData] = useState([]);

  const handleCourseMaterialStatusUpdate = async (item, statusUpdate) => {
    if (!item.id) {
      console.error("Document ID is undefined or null");
      return;
    }
    try {
      const docRef = doc(firestore, `course_material/${item.id}`);
      await updateDoc(docRef, {
        status: {
          ...item.status,
          [course_id]: statusUpdate
        }
      });
      console.log("Document status updated successfully.");
    } catch (error) {
      console.error("Error updating document status:", error);
    }
  };

  const handleShareCourseMaterial = async (item) => {
    try {
      const payload = {
        ...item,
        receiverCourseId: Number(course_id)
      };
      const response = await httpCallables.coursesFunctions({
        func_name: "handleShareCourseMaterial",
        payload
      });

      if (response?.data?.success) {
        handleCourseMaterialStatusUpdate(item, ACCEPTENCE_STATUS.ACCEPTED);
        dispatch(fetchTeacherCourseTasks({ course_id }));
        httpCallables.readUserCoursesAndTexts().then(({ data }) => {
          const { texts } = JSON.parse(data);
          dispatch(setTexts(texts));
        });
      }
    } catch (error) {
      console.error("Error sharing course material:", error);
      dispatch(
        addSnackbar({
          message: intl.formatMessage({
            id: "shareCourseMaterial.error",
            defaultMessage: "Couldn't share course material. Please try again."
          })
        })
      );
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      courseMaterialRef,
      (querySnapshot) => {
        const newData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (
            data.type === bannerType &&
            data.courses.includes(Number(course_id)) &&
            data.status[course_id] === ACCEPTENCE_STATUS.PENDING
          ) {
            newData.push({ id: doc.id, ...data }); // Include the document ID in the data object
          }
        });
        setCourseMaterialData(newData);
        setBannerShown(newData.length > 0);
      },
      (error) => {
        captureException(error);
        throw new Error("Error getting course materials");
      }
    );

    return () => unsubscribe();
  }, [firestore, bannerType, course_id]);

  const renderBannerContent = () => {
    if (!courseMaterialData.length) return null;
    return (
      <Box sx={{ width: "100%", position: "relative" }}>
        {courseMaterialData.map((item, index) => (
          <Box
            key={index}
            sx={{
              padding: "16px",
              backgroundColor: "background.default",
              position: "absolute",
              width: "100%",
              zIndex: "1000"
            }}>
            <Banner
              title="New course materials"
              body={`${item.sender} wants to share some course materials with you`}
              actions={
                <>
                  <Button
                    size="medium"
                    onClick={() =>
                      handleCourseMaterialStatusUpdate(
                        item,
                        ACCEPTENCE_STATUS.DENIED
                      )
                    }>
                    Deny
                  </Button>
                  <Button
                    onClick={async () => {
                      handleShareCourseMaterial(item);
                    }}
                    size="medium">
                    Accept
                  </Button>
                </>
              }
              showCloseIcon={false}
            />
          </Box>
        ))}
      </Box>
    );
  };

  return renderBannerContent();
};

export default ExportBanner;
