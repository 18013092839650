import { httpCallables, auth } from "../firebase";
import store from "../redux/store";
import { v4 as uuid } from "uuid";

import {
  createComment,
  createContainer,
  deleteRealtimeInteraction,
  updateRealtimeInteraction
} from "../redux/realtimeInteractionsSlice";
import { addSnackbar } from "../redux/snackbarSlice";
import { captureException } from "../utils/errorHandlers";
import { INTERACTION_TYPES, INTERACTION_SUBTYPES } from "../consts";
import { updateComment } from "../redux/interactionsSlice";

const commentsAPI = {
  createThread: async function (params) {
    const {
      // The ids and created will be passed in case of undo.otherwise they will be created
      id = null,
      interaction_id = null,
      created_at = null,
      content,
      rich_text,
      privacy,
      cfi,
      text_id,
      course_id,
      pdfPosition = null,
      isPdf = false,
      task_id,
      text_name
    } = params;

    let container = {
      id: interaction_id || uuid(),
      created_at: created_at || new Date().toISOString(),
      cfi,
      privacy,
      content: " ",
      interaction_type: INTERACTION_TYPES.CONTAINER,
      interaction_subtype: INTERACTION_TYPES.COMMENT,
      text_id: text_id,
      course_id,
      task_id
    };

    let comment = {
      id: id || uuid(),
      created_at: created_at || new Date().toISOString(),
      interaction_id: container.id,
      content,
      privacy: privacy,
      rich_text: rich_text,
      interaction_type: INTERACTION_TYPES.COMMENT,
      interaction_subtype: INTERACTION_SUBTYPES.COMMENT,
      text_id,
      course_id,
      cfi,
      task_id,
      text_name
    };

    if (isPdf) {
      comment = {
        ...comment,
        pdfPosition,
        isPdf
      };
      container = {
        ...container,
        pdfPosition,
        isPdf
      };
    }

    try {
      // update redux
      store.dispatch(createContainer(container));
      store.dispatch(
        createComment({
          ...comment,
          // adding the user name and uid for the initial optimisic ui. However, we do not want to pass there values to the DB call to prevent tempering.
          user_uid: auth.currentUser.uid,
          user_name: auth.currentUser.displayName
        })
      );
      // DB call
      httpCallables.createRealtimeInteraction({
        ...comment,
        createWithContainer: true
      });
      return true;
    } catch (err) {
      store.dispatch(
        addSnackbar({
          message: {
            id: "error.createCommentFaild",
            defaultMessage:
              "There was a problem publishing a comment, please try again"
          }
        })
      );
      // Rollback
      // store.dispatch(deleteComment(id));
      captureException(
        err,
        `Failed to create comment with container. user: ${auth.currentUser.uid}, comment: ${comment}`
      );
      return false;
    }
  },
  createComment: async function (params) {
    let comment = {
      ...params,
      interaction_type: INTERACTION_TYPES.COMMENT,
      interaction_subtype: INTERACTION_SUBTYPES.COMMENT
    };
    if (!params.created_at) {
      comment = {
        id: uuid(),
        ...comment,
        created_at: new Date().toISOString()
      };
    }
    try {
      // update redux
      store.dispatch(
        createComment({
          ...comment,
          // adding the user name and uid for the initial optimisic ui. However, we do not want to pass there values to the DB call to prevent tempering.
          user_uid: auth.currentUser.uid,
          user_name: auth.currentUser.displayName
        })
      );

      // DB call
      httpCallables.createRealtimeInteraction(comment);
      return true;
    } catch (err) {
      store.dispatch(
        addSnackbar({
          message: {
            id: "error.createCommentFaild",
            defaultMessage:
              "There was a problem publishing a comment, please try again"
          }
        })
      );
      // Rollback
      store.dispatch(deleteRealtimeInteraction(comment));
      captureException(
        err,
        `Failed to create comment with container. user: ${auth.currentUser.uid}, comment: ${comment}`
      );
      return false;
    }
  },

  updateComment: async function (params) {
    const comment = {
      ...params
    };
    try {
      // creating the comment
      store.dispatch(updateRealtimeInteraction(comment));
      store.dispatch(updateComment(comment));
      // DB call
      httpCallables.updateRealtimeInteraction(comment);
      return true;
    } catch (err) {
      store.dispatch(
        addSnackbar({
          message: {
            id: "error.createCommentFaild",
            defaultMessage:
              "There was a problem publishing a comment, please try again"
          }
        })
      );
      // Rollback
      store.dispatch(deleteRealtimeInteraction(comment));
      captureException(
        err,
        `Failed to create comment. user: ${auth.currentUser.uid}, comment: ${comment}`
      );
      return false;
    }
  },
  deleteComment: async function (comment) {
    try {
      store.dispatch(deleteRealtimeInteraction(comment));
      httpCallables.deleteRealtimeInteraction(comment).then(({ data }) => {
        const { success } = data;
        if (success) {
          const { undoData } = JSON.parse(data.payload);
          store.dispatch(
            addSnackbar({
              message: {
                id: "comments.onDelete",
                defaultMessage: "Comment deleted"
              },
              actions: [
                {
                  intlId: "undo",
                  intlDefaultMsg: "undo",
                  callBack: "undoDeleteComment"
                }
              ],
              data: {
                comment: undoData
              }
            })
          );
        } else {
          // TODO: alert user?
          let error = new Error(`DELETE_COMMENT_FAILED`);
          error.message = data.error;
          error.data = { comment };
        }
      });

      return true;
    } catch (err) {
      // Rollback
      store.dispatch(
        addSnackbar({
          message: {
            id: "error.deleteFaild",
            defaultMessage:
              "There was a problem deleting the comment, please try again"
          }
        })
      );
      store.dispatch(createComment(comment));
      captureException(
        err,
        `Failed to deletecomment. user ${auth.currentUser.uid}, questionId:${comment.id}`
      );
      return false;
    }
  }
  // deleteThread: async function () {}
};

export default commentsAPI;
