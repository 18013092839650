import { createSelector, createSlice, current } from "@reduxjs/toolkit";
import { USER_TYPE, REDUX_ACTIONS } from "../consts";
import { splitParagraphs } from "./epics/eMentorEpics/textUtils";
import { setSelectedInteractionId } from "./interactionsSlice";

export const CHATBOX_STATUS = {
  CLOSED: "CLOSED",
  OPEN: "OPEN"
};

export const CONVERSATION_TYPES = {
  INCOMING: "INCOMING",
  OUTGOING: "OUTGOING"
};

const initialState = {
  // the fab should have it's own slice once there are more actions
  status: CHATBOX_STATUS.CLOSED,
  mood: "INTERESTED",
  submission_id: null,
  selectedInteraction: null, // Duplicting state to simplify the logic
  sessionId: null, // the current session id
  loadingSessions: [], // sessions that are currently loading
  interactions: [] // interactions for all conversations
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(REDUX_ACTIONS.LOCATION_CHANGED, (state) => {
        state.status = CHATBOX_STATUS.CLOSED;
        state.sessionId = null;
        state.interactions = [];
        state.submission_id = null;
        state.selectedInteraction = null;
      })
      .addCase(
        // Anchor = the first interaction in the conversation, when interaction_id = the question id

        setSelectedInteractionId,
        (state, value) => {
          // When changing selected interaction, set session Id to the anchor if already exists
          const interactionId = value.payload;
          const anchor = state.interactions.find(
            (interaction) => interaction.interaction_id === interactionId
          );

          state.sessionId = anchor?.id || interactionId;
          state.selectedInteraction = interactionId;
        }
      );
  },
  reducers: {
    sessionIsLoading(state, value) {
      const { sessionId } = value.payload;
      state.loadingSessions.push(sessionId);
    },
    sessionLoaded: (state, value) => {
      const { sessionId } = value.payload;

      const newSessions = state.loadingSessions.filter(
        (id) => id !== sessionId
      );
      state.loadingSessions = newSessions;
    },
    openChatbox: (state) => {
      state.status = CHATBOX_STATUS.OPEN;
    },
    closeChatbox: (state) => {
      state.status = CHATBOX_STATUS.CLOSED;
    },

    fetchConversation: (state, value) => {},

    incomingMessage: (state, value) => {
      let { interaction, interactionNumber } = value.payload;

      state.interactions.push({
        ...interaction,
        type: CONVERSATION_TYPES.INCOMING
      });

      if (
        state.sessionId === interaction.interaction_id &&
        interactionNumber === 0
      ) {
        state.sessionId = interaction.id;
      }
    },
    undoMessage: (state, value) => {
      state.interactions.pop();
    },
    resetChat: (state, value) => {
      const deletedInteractions = value.payload;
      state.interactions = state.interactions.filter(
        (interaction) =>
          !deletedInteractions.some(
            (deletedInteraction) => deletedInteraction.uuid === interaction.id
          )
      );
      state.sessionId = state.selectedInteraction; // In order to trigger a new conversation
    },
    outgoingMessage: (state, value) => {
      const { content } = value.payload;
      const { sessionId } = state;
      state.interactions.push({
        type: CONVERSATION_TYPES.OUTGOING,
        interaction_id: sessionId,
        content
      });

      // We fake the mentor's writing indicator by adding the session id to the loading sessions
      state.loadingSessions.push(sessionId);
    },
    initMentor: (state, value) => {
      state.status = CHATBOX_STATUS.OPEN;
    },

    setConversations: (state, value) => {
      const { interactions, submission_id, text_id } = value.payload;
      state.submission_id = submission_id;
      state.text_id = text_id;

      state.interactions = [];

      interactions.forEach((interaction) => {
        const type = // TODO: refactor. this is weak logic, it won't hold for chat between two users
          interaction.user_type === USER_TYPE.MENTOR
            ? CONVERSATION_TYPES.INCOMING
            : CONVERSATION_TYPES.OUTGOING;

        splitParagraphs(interaction.content).forEach((content) => {
          // splitParagraphs returns an array of strings, so we need to iterate over it
          state.interactions.push({
            ...interaction,
            type,
            content
          });
        });
      });

      // We might have an anchor now for the selected interaction, let's set it
      const interactionId = state.selectedInteraction;
      const anchor = state.interactions.find(
        (interaction) => interaction.interaction_id === interactionId
      );

      state.sessionId = anchor?.id || interactionId;
    }
  }
});

export const {
  openChatbox,
  closeChatbox,
  initMentor,
  fetchConversation,
  setConversations,
  incomingMessage,
  outgoingMessage,
  sessionIsLoading,
  sessionLoaded,
  undoMessage,
  resetChat
} = chatSlice.actions;

// Selectors

export const selectIsLoading = createSelector(
  [(state) => state.chat.sessionId, (state) => state.chat.loadingSessions],
  (sessionId, loadingSessions) => {
    return loadingSessions.includes(sessionId);
  }
);

export const selectConversation = createSelector(
  [(state) => state.chat.interactions, (state) => state.chat.sessionId],
  (interactions, sessionId) => {
    if (!sessionId) return [];
    return interactions
      .filter((interaction) => {
        const isAncor = interaction.id === sessionId;
        const isInConversation = interaction.interaction_id === sessionId;

        if (isAncor || isInConversation) return true;
        else return false;
      })
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  }
);

// Utils

export default chatSlice.reducer;
