export const COURSE_MATERIAL_TYPE = {
  TEXT: "texts",
  TASK: "tasks"
};

export const ACCEPTENCE_STATUS = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  DENIED: "denied"
};
