import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

const initialState = {
  messages: [],
  undoActions: []
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,

  reducers: {
    addSnackbar: (state, value) => {
      const message = { ...value.payload, id: uuid() };
      state.messages.push(message);
    },
    removeSnackbar: (state, value) => {
      const index = state.messages.findIndex((m) => m.id === value.payload);
      state.messages.splice(index, 1);
    },
    clearSnackbars: (state) => {
      state.messages = [];
    },
    addSnackbarUndoActions: (state, value) => {
      state.undoActions.push(value.payload);
    },
    removeSnackbarUndoActions: (state, value) => {
      const undoData = value.payload;
      const index = state.undoActions.findIndex(
        (u) => u.component === undoData.component && u.id === undoData.id
      );
      state.undoActions.splice(index, 1);
    }
  }
});

export const {
  addSnackbar,
  removeSnackbar,
  clearSnackbars,
  addSnackbarUndoActions,
  removeSnackbarUndoActions
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
