// Dependancies
import { httpCallables } from "../../../firebase";
import { minutesToMilliseconds } from "date-fns";
import { splitParagraphs } from "./textUtils";

// Redux
import store from "../../store";
import {
  CHATBOX_STATUS,
  openChatbox,
  sessionIsLoading,
  sessionLoaded
} from "../../chatSlice";
import { FEATURES, CHAT, FEATURE_FLAGS } from "../../../consts";

// rxjs
import { of, from, interval } from "rxjs";
import {
  tap,
  switchMap,
  map,
  concatMap,
  delayWhen,
  delay,
  take
} from "rxjs/operators";
import { checkFeatureFlag } from "../../../utils/featureFlags";

export async function checkFeature(flag, state) {
  const courseId = state.user?.userProfile?.selectedCourseId;

  const selectedCourse = state.courses.courses?.filter(
    (c) => c.id === courseId
  ); //useSelector(selectSelectedCourse);
  const institutionId = selectedCourse?.institution_id ?? 0;
  return checkFeatureFlag(flag, courseId, institutionId);
}

export function isFeatureFlagEnabled(state, flag) {
  const { firestore } = state;

  const defaultFatureFlags = {
    ...firestore?.data?.defaultConfig?.[FEATURES.CROSS_FEATURE]?.FEATURE_FLAGS,
    ...firestore?.data?.defaultConfig?.FEATURE_FLAGS
  };
  const featureFlags = {
    ...defaultFatureFlags,
    ...firestore?.data?.crossFeatureConfig?.FEATURE_FLAGS
  };
  return featureFlags ? featureFlags[flag] : false; // return false if featureFlags is undefined
}

export function fetchMentor(
  {
    userUid,
    textId,
    submissionId = null,
    questionId = null,
    currentUserResp = null,
    chatType = null
  },
  store
) {
  const featureFlagCheck = from(
    checkFeature(FEATURE_FLAGS.SPLIT_BOT, store.getState())
  );
  return featureFlagCheck.pipe(
    map((splitBotFF) => {
      const mentorBot = splitBotFF ? "mentorSplit" : "mentor";

      const BOT_TYPES = {
        [CHAT.TYPE.REVIEW]: mentorBot,
        [CHAT.TYPE.READER]: mentorBot
      };

      const param = {
        func_params: {
          botType: BOT_TYPES[chatType],
          questionId,
          submissionId,
          userUid,
          textId,
          comment: currentUserResp
        },
        func_name: "mentorInteraction"
      };

      return param;
    }),
    switchMap((param) => {
      return from(httpCallables.ementoringInteraction2(param)).pipe(
        map(({ data }) => {
          const { success } = data;
          if (success) {
            return JSON.parse(data.payload);
          } else {
            const { error } = data;
            throw new Error(
              `Error fetching ementoringInteraction. ${error.message}`
            );
          }
        })
      );
    })
  );
}

export function mapStateToFetchProps(action, state) {
  const { chatType = CHAT.TYPE.REVIEW } = action.payload;
  const selectedInteractionsId = state.interactions.selectedInteractionId;
  const sessionId =
    state.chat.sessionId === selectedInteractionsId
      ? null
      : state.chat.sessionId;
  const readingSession = state.reader.readingSession.map(
    (session) => session.content
  );

  const userUid = state.firebase.auth.uid;
  const textId = Number(state.texts.selectedTextId);
  const submissionId =
    Number(state.router.location.query?.submission_id) || null;
  const questionId = state.interactions?.selectedInteractionId || null;
  const textSegment = overlapConcat(readingSession);
  const currentUserResp = action.payload?.content || null;
  return {
    chatType,
    userUid,
    textId,
    submissionId,
    questionId,
    textSegment,
    currentUserResp,
    sessionId
  };
}

export function questionHasHighlights(state) {
  // checks if current interaction has highlights, returns a boolean
  const selectedInteraction = state.interactions.selectedInteractionId;
  const highlights = state.interactions.highlights;

  return highlights.some(
    (highlight) => highlight.interaction_id === selectedInteraction
  );
}

export function openChatboxIfClosed(state) {
  if (state.chat.staus !== CHATBOX_STATUS.OPEN) {
    store.dispatch(openChatbox());
  }
}

function overlapConcat(arr) {
  let result = arr[0];

  for (let i = 0; i < arr.length - 1; i++) {
    let str1 = arr[i];
    let str2 = arr[i + 1];
    let maxLen = Math.min(str1.length, str2.length);
    let foundOverlap = false;
    for (let j = 0; j < maxLen; j++) {
      let k = maxLen - j;
      let substring1 = str1.substring(str1.length - k);
      let substring2 = str2.substring(0, k);

      if (substring1 === substring2) {
        result += str2.substring(k);
        foundOverlap = true;
        break;
      }
    }
    if (!foundOverlap) result += `[...] ${str2}`;
  }
  return result;
}

export function orchestrateMentorResponse(
  interaction,
  sessionId,
  interactionNumber
) {
  // fakeing a chat bubble delay as if the user is chating with a mentor
  const parsedMentorResponse = splitParagraphs(interaction.content);
  return from(parsedMentorResponse).pipe(
    concatMap((content, index) =>
      of(content).pipe(
        take(parsedMentorResponse.length),
        delay(1000), // pause between showing a chat bubble and the loading state
        tap(() => store.dispatch(sessionIsLoading({ sessionId }))),
        delayWhen(() => calculateChatBubbleDelay(index, parsedMentorResponse)),
        map(() => ({
          interaction: { ...interaction, content: parsedMentorResponse[index] },
          interactionNumber
        })),
        tap(() => {
          store.dispatch(sessionLoaded({ sessionId }));
        })
      )
    )
  );
}

function calculateChatBubbleDelay(index, mentorResponse) {
  // delay bubble by the time it takes to read the previous one
  const isFirstChatBubble = index === 0;
  const previousContent = mentorResponse[index - 1];

  return interval(
    !isFirstChatBubble ? calculateAvarageReadingTimeForText(previousContent) : 0
  );
}

function calculateAvarageReadingTimeForText(text) {
  const wordsPerMinute = 350; // a little faster then the average reading speed for an adult
  const words = text.split(" ").length;
  const minutes = words / wordsPerMinute;
  const delayInMilliseconds = minutesToMilliseconds(minutes);
  return delayInMilliseconds;
}
