import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  inMemoryPersistence,
  indexedDBLocalPersistence,
  initializeAuth
} from "firebase/auth";
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager
} from "firebase/firestore";

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator
} from "firebase/functions";
import { getStorage } from "firebase/storage";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue
} from "firebase/remote-config";
import { captureException } from "./utils/errorHandlers";

function getFirebaseChannelName() {
  let channelSuffix = false;
  if (typeof window !== "undefined") {
    // Check for Firebase hosting defaults first
    if (window.__FIREBASE_DEFAULTS__?.hostingConfig?.channel) {
      const channel = window.__FIREBASE_DEFAULTS__.hostingConfig.channel;
      // Return the channel name if it's not the live/production channel
      channelSuffix = channel !== "live" ? channel : "";
    }

    // Fallback: Check the URL pattern
    const hostname = window.location.hostname;
    if (hostname.includes("--")) {
      // Preview URLs have format: channel-id--project-id.web.app
      // Extract the channel name (everything before the --)
      channelSuffix = hostname.split("--")[0];
    }
  }
  return channelSuffix ? `-${channelSuffix}` : "";
}

function getEmulated() {
  const [, emulated] =
    window.location.search.match(/[?&]emulated=(true|false)&?/) || [];
  if (emulated) localStorage.setItem("conf.emulated", emulated);
  return "true" === localStorage.getItem("conf.emulated");
}

export function getFirebaseConfig() {
  const firebaseConfig = {
    stage: {
      apiKey: "AIzaSyBIu1VSZ1DI_5TGkyHw-MJb2urhqVe7g1g",
      authDomain: "pangea-stage.firebaseapp.com",

      projectId: "pangea-stage",
      storageBucket: "pangea-stage.appspot.com",
      messagingSenderId: "247009010465",
      appId: "1:247009010465:web:7d44ef0cdda8ec5a8a5460"
    },
    demo: {
      apiKey: "AIzaSyAlz4k4hwaq_bPymSbJoPQPWr-xofqITUs",
      authDomain: "pangea-demo-3d15d.firebaseapp.com",
      projectId: "pangea-demo",
      storageBucket: "pangea-demo.appspot.com",
      messagingSenderId: "867375218514",
      appId: "1:867375218514:web:efca0d2a0f6124eea4448b"
    },
    prod: {
      apiKey: "AIzaSyAKxk1aU1FAxsZiYmbFl4QoIucFMTDCc0s",
      authDomain: "app.alethea.co",
      projectId: "alethea-dda54",
      storageBucket: "alethea-dda54.appspot.com",
      messagingSenderId: "757420351402",
      appId: "1:757420351402:web:0095e4780ee5bf1c7455d3"
    },
    dev: {
      apiKey: "AIzaSyCNAlt21x9RH_RFMoP3IYI9ZYDkHXyRZVs",
      authDomain: "alethea-dev.firebaseapp.com",
      domains: [
        "alethea-dev.firebaseapp.com",
        "dev.alethea.co",
        "alethea-dev.web.app"
      ],
      projectId: "alethea-dev",
      storageBucket: "alethea-dev.appspot.com",
      messagingSenderId: "758494664468",
      appId: "1:758494664468:web:29daa99ca8d27bda1dd401"
    }
  };
  const isEmulated = getEmulated();
  const { search, hostname } = window.location;

  //detect env from domain/subdomain, default to 'prod'
  let subdomain = (hostname.match(/^(demo|stage|local)/) || ["prod"])[0];
  if (subdomain === "local" || hostname === "127.0.0.1") {
    subdomain = "dev";
  }

  // check if hostname is authDomain for some entry
  Object.entries(firebaseConfig).forEach(([key, value]) => {
    if (value.authDomain === hostname || value.domains?.includes(hostname)) {
      subdomain = key;
    }
  });

  //if channnel - take the projectId prefix of the firebae url
  const projectIdMatch = hostname.match(/(.*)--.*/);
  if (projectIdMatch && projectIdMatch[1]) {
    Object.entries(firebaseConfig).forEach(([key, value]) => {
      if (value.projectId === projectIdMatch[1]) {
        subdomain = key;
      }
    });
  }

  let backend = subdomain;

  //detect if developer/tester directs to override
  const qsBackend = (search.match(/[?&]backend=([^&]*)&?/) || [])[1];
  if (typeof qsBackend !== "undefined") {
    if (qsBackend === "" || qsBackend === backend) {
      localStorage.removeItem("conf.backend");
    } else {
      backend = qsBackend;
      localStorage.setItem("conf.backend", backend);
    }
  }

  backend = localStorage.getItem("conf.backend") || backend;

  //pull selected config-set, default to prod
  let envConfig = firebaseConfig[backend];
  if (!envConfig) {
    backend = "prod";
    envConfig = firebaseConfig[backend];
  }

  //pull local overrides from conf.dev
  const json = localStorage.getItem("conf.dev");
  let localOverrides = {};
  if (json) {
    try {
      localOverrides = JSON.parse(json);
      console.warn(
        "lodaded local overriding values for firebase",
        localOverrides
      );
    } catch (err) {
      captureException(err, `Failed to parse conf.dev ${json}`);
    }
  }

  const options = {
    ...envConfig,
    ...localOverrides
  };

  return {
    options,
    measurementId: options.measurementId,
    crossed: backend !== subdomain && backend,
    isEmulated,
    backend
  };
}

export const firebaseConfig = getFirebaseConfig();
export const projectId = firebaseConfig.options.projectId;
export const environment = firebaseConfig.backend + getFirebaseChannelName();

export const crossed = firebaseConfig.crossed;
export const isEmulated = firebaseConfig.isEmulated;

// Initialize Firebase app
const app = initializeApp(firebaseConfig.options, {
  experimentalForceLongPolling: true,
  experimentalAutoDetectLongPolling: true
});

// Initialize Auth with persistence
const canUseDom =
  Object.getOwnPropertyDescriptor(window, "window")
    ?.get?.toString()
    .includes("[native code]") ?? false;
const isInPlaywright = window.playwrightTest;
const persistence = [];
if (canUseDom) {
  if (!isInPlaywright) {
    persistence.push(indexedDBLocalPersistence);
  }
  persistence.push(browserLocalPersistence);
} else {
  persistence.push(inMemoryPersistence);
}
export const auth = initializeAuth(
  app,
  canUseDom
    ? {
        persistence,
        popupRedirectResolver: browserPopupRedirectResolver
      }
    : {
        persistence
      }
);
export const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ prompt: "select_account" });

// Initialize other services
const region = projectId === "alethea-dda54" ? "us-central1" : "europe-west3";
export const functions = getFunctions(app, region);
export const firestore = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager()
  })
});

export const storage = getStorage(app);

// Initialize remote config
export const remoteConfig = getRemoteConfig(app);
export const getRecaptchaKey = () =>
  getValue(remoteConfig, "recaptcha_key").asString();
export const env = "staging";
remoteConfig.settings.minimumFetchIntervalMillis = 300000;

remoteConfig.defaultConfig = JSON.parse(
  localStorage.getItem("conf.lastRemoteConfig") || "{}"
);

fetchAndActivate(remoteConfig).then(() => {
  localStorage.setItem(
    "conf.lastRemoteConfig",
    JSON.stringify(remoteConfig.defaultConfig)
  );
});

if (isEmulated) {
  connectFunctionsEmulator(functions, "localhost", 5001);
  // connectFirestoreEmulator(firestore, "localhost", 8080);
  // connectStorageEmulator(storage, "localhost", 9199);
}

// HTTP Callable Functions
export const httpCallables = {
  createCourseText: httpsCallable(functions, "texts-createCourseText"),
  getThumbnailsDownloadURL: httpsCallable(
    functions,
    "texts-getThumbnailsDownloadURL"
  ),
  readConversationByInteractionId: httpsCallable(
    functions,
    "interaction-readConversationByInteractionId"
  ),
  createLearningSession: httpsCallable(
    functions,
    "sessions-createLearningSession"
  ),
  adminFunctions: httpsCallable(functions, "adminFunctions-adminFunctions"),
  adminGetTeacherNameById: httpsCallable(
    functions,
    "adminFunctions-getTeacherNameById"
  ),
  adminGetStats: httpsCallable(functions, "stats-getStats"),
  ementoringInteraction2: httpsCallable(functions, "ementoring-interactionsv2"),
  getUser: httpsCallable(functions, "users-getUser"),
  readDailyCourseActivityReport: httpsCallable(
    functions,
    "reports-readDailyCourseActivityReport"
  ),
  readAggregatedCourseActivity: httpsCallable(
    functions,
    "reports-readAggregatedCourseActivity"
  ),
  readDailyUsersActivityReport: httpsCallable(
    functions,
    "reports-readDailyUsersActivityReport"
  ),
  readTasksAndSubmissionsInRange: httpsCallable(
    functions,
    "reports-readTasksAndSubmissionsInRange"
  ),
  generateUserActivityReport: httpsCallable(
    functions,
    "reports-generateUserActivityReport"
  ),
  getMaxPagePerText: httpsCallable(functions, "reports-getMaxPagePerText"),
  readCourseUsers: httpsCallable(functions, "courses-readCourseUsers"),
  readTextComments: httpsCallable(
    functions,
    "realtimeInteractions-readTextComments"
  ),
  updateRealtimeInteraction: httpsCallable(
    functions,
    "realtimeInteractions-updateRealtimeInteraction"
  ),
  deleteRealtimeInteraction: httpsCallable(
    functions,
    "realtimeInteractions-deleteRealtimeInteraction"
  ),
  createRealtimeInteraction: httpsCallable(
    functions,
    "realtimeInteractions-createRealtimeInteraction"
  ),
  readUserCoursesAndTexts: httpsCallable(
    functions,
    "courses-readUserCoursesAndTexts"
  ),
  taskFunctions: httpsCallable(functions, "tasks-taskFunctions"),
  submitPeerReviewReply: httpsCallable(
    functions,
    "courses-submitPeerReviewReply"
  ),
  submitStandardTask: httpsCallable(
    functions,
    "tasks-updateTaskSubmissionToSubmitted"
  ),
  readActiveGrTask: httpsCallable(functions, "tasks-readActiveGrTask"),
  generateCourseActivityReport: httpsCallable(
    functions,
    "reports-generateCourseActivityReport"
  ),
  generateCourseActivityReport_OLD_VERSION: httpsCallable(
    functions,
    "reports_old-generateCourseActivityReport_OLD_VERSION"
  ),
  interactionFunctions: httpsCallable(
    functions,
    "interactions-interactionFunctions"
  ),
  textFunctions: httpsCallable(functions, "texts-textFunctions"),
  deleteCourseText: httpsCallable(functions, "texts-deleteCourseText"),
  updateCourseText: httpsCallable(functions, "texts-updateCourseText"),
  reorderCourseTexts: httpsCallable(functions, "texts-reorderCourseTexts"),
  courseUserFunctions: httpsCallable(
    functions,
    "adminFunctions-userCourseFunctions"
  ),
  institutionsFunctions: httpsCallable(
    functions,
    "adminFunctions-institutionFunctions"
  ),
  adminLMSFunctions: httpsCallable(functions, "adminFunctions-LMSFunctions"),
  coursesFunctions: httpsCallable(functions, "courses-coursesFunctions"),
  confirmPasswordReset: httpsCallable(functions, "users-confirmPasswordReset"),
  sendPasswordReset: httpsCallable(functions, "users-sendPasswordReset"),
  createUser: httpsCallable(functions, "users-createUser"),
  contactAlethea: httpsCallable(functions, "users-contactAlethea"),
  NotifySupportOnfailedToUploadText: httpsCallable(
    functions,
    "texts-NotifySupportOnfailedToUploadText"
  ),
  setCoursesStatus: httpsCallable(functions, "courses-setStatus"),
  undoStudentTaskSubmissions: httpsCallable(
    functions,
    "courses-undoStudentTaskSubmissions"
  ),
  undoSubmitPeerReviewReflection: httpsCallable(
    functions,
    "courses-undoSubmitPeerReviewReflection"
  ),
  undoSubmitPeerReviewResponse: httpsCallable(
    functions,
    "courses-undoSubmitPeerReviewResponse"
  ),
  undoSubmitPeerReviewReply: httpsCallable(
    functions,
    "courses-undoSubmitPeerReviewReply"
  ),
  undoTaskFeedbackSubmission: httpsCallable(
    functions,
    "courses-undoTaskFeedbackSubmission"
  )
};
