import { createSlice } from "@reduxjs/toolkit";
import { getFirestore, doc, setDoc } from "firebase/firestore";

const initialState = {
  data: {},
  ordered: {},
  cache: {},
  listeners: {},
  queries: {},
  status: {
    requesting: {},
    requested: {},
    timestamps: {}
  },
  errors: {}
};

export const updateDocument = (path, payload) => {
  return async () => {
    try {
      const firestore = getFirestore();
      const docRef = doc(firestore, path);
      await setDoc(docRef, payload, { merge: true });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };
};

const firestoreSlice = createSlice({
  name: "firestore",
  initialState,
  reducers: {
    setListener: (state, action) => {
      const { path } = action.payload;
      state.listeners[path] = true;
    },
    removeListener: (state, action) => {
      const { path } = action.payload;
      delete state.listeners[path];
    },
    setDocData: (state, action) => {
      const { path, data } = action.payload;
      const segments = path.split("/");

      // For nested paths, use the last two segments
      const collection =
        segments.length > 1 ? segments[segments.length - 2] : segments[0];
      const docId = segments[segments.length - 1];

      // Initialize collection if it doesn't exist
      if (!state.data[path]) {
        state.data[path] = {};
        state.ordered[path] = [];
      }

      // Update document data
      state.data[path] = {
        ...data,
        id: docId
      };

      // Update ordered array
      const orderedIndex = state.ordered[path]?.findIndex(
        (item) => item.id === docId
      );
      if (orderedIndex === -1) {
        state.ordered[path] = [{ ...data, id: docId }];
      } else {
        state.ordered[path][orderedIndex] = { ...data, id: docId };
      }

      // Update status
      state.status.timestamps[path] = Date.now();
      state.status.requested[path] = true;
      state.status.requesting[path] = false;
    },

    setCollectionData: (state, action) => {
      const { collection: collectionPath, data } = action.payload;

      // Initialize collection
      if (!state.data[collectionPath]) {
        state.data[collectionPath] = {};
        state.ordered[collectionPath] = [];
      }

      // Update data
      state.data[collectionPath] = {};
      state.ordered[collectionPath] = [];

      Object.entries(data).forEach(([docId, docData]) => {
        state.data[collectionPath][docId] = { ...docData, id: docId };
        state.ordered[collectionPath].push({ ...docData, id: docId });
      });

      // Update status
      state.status.timestamps[collectionPath] = Date.now();
      state.status.requested[collectionPath] = true;
      state.status.requesting[collectionPath] = false;
    },
    updateDoc: (state, action) => {
      const { collection, docId, data } = action.payload;

      if (state.data[collection]?.[docId]) {
        state.data[collection][docId] = {
          ...state.data[collection][docId],
          ...data
        };

        // Update ordered array
        const orderedIndex = state.ordered[collection].findIndex(
          (item) => item.id === docId
        );
        if (orderedIndex !== -1) {
          state.ordered[collection][orderedIndex] = {
            ...state.ordered[collection][orderedIndex],
            ...data
          };
        }
      }
    },
    deleteDoc: (state, action) => {
      const { collection, docId } = action.payload;

      if (state.data[collection]?.[docId]) {
        delete state.data[collection][docId];
        state.ordered[collection] = state.ordered[collection].filter(
          (item) => item.id !== docId
        );
      }
    },
    setError: (state, action) => {
      const { path, error } = action.payload;
      state.errors[path] = error;
    },
    clearError: (state, action) => {
      const { path } = action.payload;
      delete state.errors[path];
    },
    clearData: (state, action) => {
      const { path } = action.payload;
      if (path) {
        const [collection, doc] = path.split("/");
        if (doc) {
          delete state.data[collection]?.[doc];
          state.ordered[collection] =
            state.ordered[collection]?.filter((item) => item.id !== doc) || [];
        } else {
          delete state.data[collection];
          delete state.ordered[collection];
        }
      } else {
        // Clear all data
        state.data = {};
        state.ordered = {};
      }
    }
  }
});

export const {
  setListener,
  removeListener,
  setDocData,
  setCollectionData,
  updateDoc,
  deleteDoc,
  setError,
  clearError,
  clearData
} = firestoreSlice.actions;

// Selectors
export const selectCollection = (state, collection) =>
  state.firestore.data[collection];
export const selectDoc = (state, collection, docId) =>
  state.firestore.data[collection]?.[docId];
export const selectOrdered = (state, collection) =>
  state.firestore.ordered[collection];
export const selectIsRequesting = (state, path) =>
  state.firestore.status.requesting[path];
export const selectError = (state, path) => state.firestore.errors[path];

export default firestoreSlice.reducer;
