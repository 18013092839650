// service to navigate between screens outside components
let navigator = null;

export const navigationService = {
  setNavigator(nav) {
    navigator = nav;
  },

  navigate(to, options) {
    if (!navigator) {
      console.warn("Navigation attempted before navigator was set");
      return;
    }
    navigator(to, options);
  }
};
